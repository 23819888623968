import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../layout'
import { UserAuthContext } from '../../../context/AuthContext';
import { links } from '../../../routes';
import { useNavigate, useParams } from 'react-router-dom';

export function UserPage() {
  const {slug} = useParams()
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.user + slug}`)
    }
  }, [token, navigate, slug]);
  console.log(info)
  return (
    <MainLayout title='Annonceur'>
        <div></div>
    </MainLayout>
  )
}
