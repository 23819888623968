import axios from "axios"
import {APIURL} from "../../utils";

const API_URL = APIURL + "auth/";

const login = async (data: any) => {
    const response = await axios.post(API_URL + 'login', data)
    
    if (response.data) {
        console.log(response.data)
        let token = response?.data?.access_token
        window.localStorage.setItem('enka-auth', token)
        return response.data
    }
}
const loginWithGoogle = async (data: any) => {
    const response = await axios.post(API_URL + 'login-with-google', data)
    
    if (response.data) {
        console.log(response.data)
        let token = response?.data?.access_token
        window.localStorage.setItem('enka-auth', token)
        return response.data
    }
}
const getCitiesOption =  async ( ) => {


    const response = await axios.get(APIURL + "city/list")
    let tabs:any[] = []
    if(response.data){
        
        let cities: any[] = response.data || []
        
        cities.forEach((el: any) => {
            let item = {
                value: el._id,
                name: el.name,
            }

            tabs = [...tabs, item]
        })
    }
    console.log(tabs)
    return tabs
}

const logout = () => {
    window.localStorage.removeItem('enka-auth')
}

const authService = {
    login, logout, getCitiesOption, loginWithGoogle
}

export default authService