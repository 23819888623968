import React from 'react'
import { MainLayout } from '../../../layout'

export function TermsPage() {
  return (
    <MainLayout title="Conditions d'utilisation">
      <div className='enka-home'>

        <div className='enka-home__header'>
          <h1>Conditions générales d'utilisation</h1>
        </div>
        <br />
        <div className='enka-home-rules'>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 1 : Mentions légales et définitions</h2>
            <div className='enka-home-rule__container'>
              <p>Le service enka-market accessible via le site Internet www.enka-market.com est un
                  service personnalisé, de diffusion de petites annonces. L'accès au site, sa consultation et son
                  utilisation sont subordonnés à l’acceptation sans réserve des présentes Conditions Générales
                  d’utilisation de enka-market. Le site est édité par <strong>Hisia Studio</strong> et hébergé sur <strong>Infomaniak</strong>.
                  Le Directeur de Publication de Enka-market est <strong>Monsieur Arnaud N’DO TRAORE</strong> </p>
              <p> <strong>Acheteur :</strong> désigne tout Utilisateur personne physique ou morale et entreprises
                  titulaires d’un Compte Personnel qui achète ou souhaite acheter un ou plusieurs Biens sur le
                  Site à un Vendeur.</p>
              <p> <strong>Vendeur :</strong> désigne tout Annonceur Particulier ou Professionnel qui propose un ou
              plusieurs Biens à la vente..</p>
              <p> <strong>Annonce :</strong> désigne l'ensemble des éléments et données (visuelles, textuelles, sonores,
                  photographies, dessins), déposé par un Annonceur sous sa responsabilité éditoriale, en vue
                  d'acheter ou de vendre un bien ou de proposer ou de rechercher un service.</p>
              <p> <strong>Compte enka-market :</strong> désigne l'espace gratuit, accessible depuis le site Internet,
                  que tout Annonceur doit se créer, et à partir duquel il peut diffuser, gérer, visualiser ses
                  Annonces, souscrire à des options payantes.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 2 : Acceptation</h2>
            <div className='enka-home-rule__container'>
              <p>Les CGU ont pour objet de déterminer les conditions d'utilisation du Service enka-
                  market mis à disposition des Utilisateurs, des Annonceurs, des Vendeurs, des Acheteurs via le Site. Tout Utilisateur - tout Annonceur, tout Acheteur, tout Vendeur, déclare en accédant et
                  utilisant le service enka-market, depuis le Site, avoir pris connaissance des présentes
                  Conditions Générales d’Utilisation et les accepter expressément sans réserve et/ou
                  modification de quelque nature que ce soit. Les présentes CGU sont donc pleinement
                  opposables aux Utilisateurs, aux Annonceurs, aux Acheteurs, aux Vendeurs.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 3 : Données personnelles</h2>
            <div className='enka-home-rule__container'>
              <p>Le site assure à l'Utilisateur une collecte et un traitement d'informations
                personnelles dans le respect de la vie privée. L’Utilisateur dispose d'un droit d'accès, de
                rectification, de suppression et d'opposition de ses données personnelles. Toute Annonce qui
                ne serait pas conforme aux règles de diffusion du Service enka-market et/ou qui serait
                susceptible de porter atteinte aux droits d'un tiers sera supprimer sans préavis ni indemnité. Il
                est permis à tout Utilisateur de signaler un contenu abusif à partir du site via un formulaire de
                contact.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 4 : Utilisation du service enka-market</h2>
            <div className='enka-home-rule__container'>
              <p>Le Service enka-market, accessible via le site Internet www.enka-market.com est
un service personnalisé, de diffusion de petites annonces. Sans utilisation d’un compte, seule
la consultation d’annonces est accessible. Tout utilisateur déclare être informé qu’il devra,
pour accéder au Service enka-market, disposer d’un accès à l’Internet souscrit auprès du
fournisseur de son choix, dont le coût est à sa charge. L’utilisateur s’engage, le cas échéant, à
respecter et à maintenir la confidentialité des Identifiants de connexion à son Compte
Personnel et reconnaît expressément que toute connexion à son Compte Personnel, ainsi que
toute transmission de données depuis son Compte Personnel sera réputée avoir été effectuée
par l’Annonceur. Toute perte, détournement ou utilisation des Identifiants de connexion et
leurs éventuelles conséquences relèvent de la seule et entière responsabilité de l’Annonceur.
Toute Annonce publiée sera diffusée sur le Site. Tout traitement de données personnelles dans
le cadre des présentes est
soumis aux dispositions de notre politique de confidentialité, qui fait partie intégrante des
présentes CGU.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 5 : Propriété intellectuelle</h2>
            <div className='enka-home-rule__container'>
              <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son...)
font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement
par le droit d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du site pour toute
reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des
contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et
publicitaires est strictement interdite. Toute représentation totale ou partielle de ce site par
quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet
constituerait une contrefaçon sanctionnée il est rappelé que l’Utilisateur qui reproduit, copie
ou publie le contenu protégé doit citer l’auteur et sa source.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 6 : Responsabilité</h2>
            <div className='enka-home-rule__container'>
              <p> <strong>L'utilisateur</strong> s'engage à ne proposer dans les Annonces que des biens
              disponibles dont il dispose. L'Annonceur s'engage, en cas d'indisponibilité du bien, à procéder
              au retrait de l'Annonce du Service enka-market dans les plus brefs délais.</p>
              <p><strong>enka-market</strong> ne saurait donc en aucun cas être tenue responsable du contenu des
              Annonces publiées par les Annonceurs ainsi que des messages et contenus échangés via la
              messagerie et ne donne aucune garantie, expresse ou implicite, à cet égard. enka-market est
              un tiers aux correspondances et relations entre les Annonceurs et les Utilisateurs, et exclut de
              ce fait toute responsabilité à cet égard.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 7 : Liens hypertextes</h2>
            <div className='enka-home-rule__container'>
              <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé
qu’en cliquant sur ces liens, il sortira du site enka-market. Ce dernier n’a pas de contrôle sur
les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable
de leur contenu.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 8 : Cookies</h2>
            <div className='enka-home-rule__container'>
              <p>Lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son
logiciel de navigation. Les cookies sont de petits fichiers stockés temporairement sur le disque
dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation
du site enka-market. Les cookies ne contiennent pas d’information personnelle et ne peuvent
pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré
aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur,
d’autres restent. L’information contenue dans les cookies est utilisée pour améliorer le site. En
naviguant sur le site, L’Utilisateur les accepte. A défaut d’acceptation, l’accès à certaines
fonctionnalités ou pages peuvent être restreinte.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>ARTICLE 9 : Droit applicable et juridiction compétente</h2>
            <div className='enka-home-rule__container'>
              <p>En cas de non-respect des conditions générales de vente et/ou d’utilisation, le site
enka-market aura la possibilité de suspendre voire de fermer le compte d’un Utilisateur après
mise en demeure adressée par voie électronique et restée sans effet dans un délais de 1 mois.
En cas d'absence de résolution amiable d'un litige né entre les parties, les
tribunaux burkinabè seront les seuls compétents pour en connaître (dans la langue française).

Pour toute question relative à l’application des présentes CGU, vous pouvez joindre via le
formulaire de contact.</p>
            </div>
          </div>
        </div>
        <br />
        <div className='enka-home__header'>
          <h1>Règles de diffusion</h1>
        </div>
        <br />
        <div className='enka-home-rules'>
          <strong>Il est strictement interdit</strong>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Photos comportant</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Des enfants mineurs ;</li>
                <li>Un ou des logo(s) seul(s) (à l&#39;exception de la catégorie d&#39;annonces Emploi et Services) ;</li>
                <li>Un ou des lien(s) renvoyant vers un site internet ;</li>
                <li>Un numéro de téléphone ou une adresse mail ;</li>
                <li>Une illustration/représentation sans lien avec l’offre proposée</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Produits &amp; services dangereux</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Tabac, drogues et objets associés ;</li>
                <li>Substances dangereuses et illicites ;</li>
                <li>Armes blanches de combat ou d’autodéfense, armes à feu, explosifs, pièges de chasse.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Produits et services destinés aux adultes</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Accessoires et contenus réservés aux adultes ;</li>
                <li>Offres proposant de manière directe ou indirecte des services/pratiques tendancieuses.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Médicaments &amp; cosmétiques</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Médicaments et parapharmacie ;</li>
                <li>Cosmétiques ;</li>
                <li>Produits présentés comme étant miraculeux ;</li>
                <li>Produits amincissants et compléments alimentaires.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Produits et services portant atteinte aux droits de propriété intellectuelles</h2>
            <div className='enka-home-rule__container'>
              <p>Vous devez être légalement propriétaire du contenu ou être autorisé à le vendre par le propriétaire, par
              son mandataire agréé ou par la loi. A ce titre :</p>
              <ul>
                <li>Tout produit contrefait est strictement prohibé ;</li>
                <li>Seule la diffusion d&#39;annonces proposant la vente de produits originaux est autorisée (appareils
électroniques, DVD, CD, jeux vidéo, logiciels informatiques, cartes pour télévision payante,
vêtements de marque et accessoires de marque etc.) ;</li>
                <li>L’insertion de photos comportant un (tatouage numérique) dans le but d&#39;assurer la protection
                des droits de propriété intellectuelle est autorisée.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Espèces protégées et produits composés d’un élément de faune ou de flore protégé</h2>
            <div className='enka-home-rule__container'>
              
              <ul>
                <li>Certaines parties ou produits dérivés d’espèces sauvages menacées et/ou protégées, comme
                l’ivoire ;</li>
                <li>Les espèces végétales et animales protégées, menacées ou prélevées dans leur milieu naturel
                visées à l’annexe A de la convention CITES.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Dons et services financiers</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Appel aux dons financiers privés ou à but humanitaire.</li>
              </ul>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Divers</h2>
            <div className='enka-home-rule__container'>
              <ul>
                <li>Certains biens immatériels tels que des préventes, billets électroniques, QR codes, NFT ou
                cryptomonnaies ;</li>
                <li>Produits portant ou détournant des emblèmes protégés ;</li>
                <li>Objets consignés ;</li>
                <li>Avis de recherche d’une personne ;</li>
                <li>La majorité des produits de grande consommation de manufacture industrielle.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
