import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { getPubs, reset } from '../../../../../redux/feature/pub/pub.slice';
import { AlertModule, Button, ModalLayout, Textfield } from '../../../../components';
import { setPost, reset as preset } from '../../../../../redux/feature/post/post.slice';

export const PostCreatePage = () => {
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  const {isCreateError, isCreateLoading, isCreateSuccess, message: aMessage} = useAppSelector((state) => state.post);
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.ajout_event}`)
    }
  }, [token, navigate]);

  const [data, setData] = useState<any>({
    titre: "",  
    description: "", 
  });
  const dispatch = useAppDispatch();
  
  const [error, setError] = useState<{ type: string, message: string }>({
    type: "",
    message: ""
  })
  const [openC, setOpenC] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPubs({}))

    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.titre){
        setError({
            type: "titre",
            message: "Veuillez renseigner le titre"
        })
        return
    }
    if(!data.description){
        setError({
            type: "description",
            message: "Veuillez renseigner la description"
        })
        return
    }
    
    setOpenC(true)
    dispatch(setPost(data))
  }

  useEffect(() => {
    if(isCreateSuccess){
      setOpenC(true)
    }
  }, [isCreateSuccess])

  if(isLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }

  if(isError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
        </div>
    )
  }

  return (
    <MainLayout title="Ajout d'une publication">
      <div className='enka-home'>
        <div className='enka-home__header'>
          <h1>Publier une annonce</h1>
        </div>
        <div className='enka-home__create enka-home-create'>
          <form className='enka-home-create__container' onSubmit={handleSubmit}>
            {
              isCreateError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={aMessage}/>
              ) : null
            }
            <div className='enka-home-create__fields'>
              <div className='enka-home-create__field'>
                <Textfield
                    id={"titre"}
                    value={data.titre}
                    require={true}
                    changeValue={(val: string) => setData({...data, titre: val})}
                    type={"text"}
                    label={"Titre"}
                    error={error}
                    placeholder={"Veuillez insérer le titre"}
                />
              </div>
              <div className='enka-home-create__field'>
                <Textfield
                    id={"description"}
                    value={data.description}
                    require={true}
                    changeValue={(val: string) => setData({...data, description: val})}
                    type={"textarea"}
                    label={"Description"}
                    error={error}
                    placeholder={"Veuillez insérer la description"}
                />
              </div>
            </div>
            <div className='enka-home-create__action'>
              <Button btnType={"submit"} label={isCreateLoading ? "Ajout en cours..." : "Publier l'annonce"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={isCreateLoading} iconLeftClass={"icon-spin"}/>
            </div>
          </form>
          <div className='enka-home-create__side'>
            {
              isSuccess && pubs ? (
                pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                  <div  className='enka-ads is--side' >
                    <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                    <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                    <span>publicité</span>
                    </a>
                  </div>
                ) : null
              ) : null
            }
          </div>
        </div>

        <ModalLayout size={"is--md"} id={"add"} fnc={() => {}} withClose={false} isOpen={openC}>
          <div className={"kubini-modal-form-status"}>
            <div className={"kubini-modal-form-status__container"}>
                <div className={"kubini-modal-form-status__icon is--success"}>
                    <i className="icon-validation-check"/>
                </div>
                <div className={"kubini-modal-form-status__text"}>
                    <h2>Publier une annonce</h2>
                    <p>Votre annonce vient d'être publiée</p>
                    <div className={"kubini-modal-form-status__action"}>
                        <Button
                            btnType={"button"}
                            label={"Revenir dans mon compte..."}
                            btnStyle={"is--neutral"}
                            active={true}
                            withIconLeft={false}
                            onClick={() => {
                              navigate(links.account+"?tabs=publication")
                            }}
                        />
                    </div>
                </div>
            </div>
          </div>
        </ModalLayout>
      </div>
    </MainLayout>
  )
}
