import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../../routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SliderNextArrow, SliderPrevArrow } from '../../home';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { commentEvent, deleteEvent, getEvent, likeEvent, reset, uncommentEvent, unlikeEvent } from '../../../../../redux/feature/event/event.slice';
import { Button, ModalLayout, Toast } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';
import { capitalize, formatMyDate, formatNumber, formatNumber2 } from '../../../../../utils/function';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const ImagesBanner = ({images}:{images: any[]}) => {
  const [imageIndex, setImageIndex] = useState<number>(0)
  let setting = {
    dot: false,
    infinite: images?.length > 4,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: images?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: images?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='enka-product-banner__images enka-product-banner-images'>
      <div className='enka-product-banner-image-main'>
        <img src={images[imageIndex]?.url} alt="main" />
      </div>
      <div className='enka-product-banner-image-list'>
        <Slider {...setting}>
          {
            images?.map((item: any, index:number) => (
              <div className='enka-product-banner-image-lil' key={index}>
                <button type='button' onClick={() => setImageIndex(index)} className={imageIndex === index ? "is--active" : ""}>
                  {
                    item?.url ? (
                      <img src={item?.url} alt="cover" />
                    ) : (
                      <i className="icon-picture-flower"></i>
                    )
                  }
                </button>
              </div>
            ))
          }
          
        </Slider>
      </div>
    </div>
  )

}

const DeleteModal = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    setStatut(false)
    closeFnc(false)
  }
  const dispatch = useAppDispatch();
  const {isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.event);
  let navigate = useNavigate()
  return (
    
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isDeleteLoading} fnc={() => { isDeleteSuccess ?  navigate(links.account+"?tabs=evenement") : closeModal()}}>
      <div className={"kubini-modal-form"}>
        {
          statut && isDeleteSuccess ? (
            <div className={"kubini-modal-form-status"}>
                <div className={"kubini-modal-form-status__container"}>
                    <div className={"kubini-modal-form-status__icon is--success"}>
                        <i className="icon-delete-bin"/>
                    </div>
                    <div className={"kubini-modal-form-status__text"}>
                        <h2>Suppression d'une annonce</h2>
                        <p>Votre annonce a bien été supprimée avec succès</p>
                        <div className={"kubini-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                  navigate(links.account+"?tabs=evenement")
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kubini-modal-form-status"}>
                <div className={"kubini-modal-form-status__container"}>
                    <div className={"kubini-modal-form-status__icon is--danger"}>
                        <i className="icon-delete-bin"/>
                    </div>
                    <div className={"kubini-modal-form-status__text"}>
                        <h2>Suppression d'une annonce</h2>
                        {
                            statut && isDeleteError ? (
                                <p>
                                    <strong>Ouppss!!!</strong> <br/>
                                    <span>{message}</span>
                                </p>
                            ) : (
                                <p>Souhaitez-vous vraiment supprimer cet élément</p>
                            )
                        }
                        <div className={"kubini-modal-form-status__action"}>
                            {
                                statut && isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Ressayer"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "kubini-icon-spin6" : "kubini-icon-x-circle"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteEvent({id: item?._id}))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Supprimer"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "kubini-icon-spin6" : "kubini-icon-x-circle"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteEvent({id: item?._id}))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

export const MyEventPage = () => {
  const {slug} = useParams()
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [typeLike, setTypeLike] = useState<string>("")
  const [commentContent, setCommentContent] = useState<string>("")
  const [readMore, setReadMore] = useState<number>(12)

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.my_event + slug}`)
    }
  }, [token, navigate, slug]);
  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, event,isEditError, isEditLoading, isEditSuccess, message} = useAppSelector((state) => state.event);
  const {isLoading: isPLoading, isError: isPError, isSuccess: isPsuccess, pubs, message: pMessage} = useAppSelector((state) => state.pub);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get('tabs')

  const [isNewNotif, setIsNewNotif] = useState<boolean>(false)

  useEffect(() => {
    if(slug){
      dispatch(getEvent(slug))
      dispatch(getPubs({}))
    }


    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch, slug])

  useEffect(() => {
    if(isEditError && typeLike){
      setIsNewNotif(true)
    }
  }, [isEditError, typeLike])

  useEffect(() => {
    if(isEditSuccess && typeLike){
      setIsNewNotif(true)
      if(typeLike === "comment"){
        setCommentContent("")
      }
      if(typeLike === "cart" || typeLike === "uncart"){
        window?.location.reload()
      }
    }
  }, [isEditSuccess, typeLike])


  useEffect(() => {
    if(event && info){
      if(event?.user?.auth?._id !== info?._id){
        navigate(links.event + slug)
      }
    }
  }, [event, info, slug, navigate])

  return (
    <MainLayout title='Evènement'>
      <div className='enka-home'>
        {
          isLoading || isPLoading ? (
          <div className={"kubini-loader"}>
            <i className="icon-spin"/>
          </div>
          ) : (
            isError || isPError ? (
              <div className={"kubini-user-error"}>
                <div className={"kubini-user-error__container"}>
                  <i className="icon-warning-triangle"/>
                  <h2>Oupss!! Une erreur est survenue</h2>
                  <p>{message || pMessage}</p>
                  <div className={"kubini-user-error__action"}>
                    <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                  </div>
                </div>
              </div>
            ) : (
              isSuccess && isPsuccess && pubs && event ? (
                event?.supptime ? (
                  <div className={"kubini-user-error"}>
                    <div className={"kubini-user-error__container"}>
                      <i className="icon-warning-triangle"/>
                      <h2>Cette annonce a été supprimée</h2>
                      <div className={"kubini-user-error__action"}>
                        <Button btnType={"button"} label={"Revenir vers le profil"} btnStyle={"is--primary"} active={true} onClick={() => navigate(links.account)}/>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                  <div className='enka-product-banner'>
                    <ImagesBanner images={event.images}/>
                    <div className='enka-product-banner__side'>
                      <div className='enka-product-banner__info enka-product-banner-info'>
                        <div className='enka-product-banner-info__details'>
                          <h2>{event.title}</h2>
                          <p>Lieu: {event?.lieu?.toUpperCase()}</p>
                          <div className='enka-product-banner-info-user'>
                            <div className='enka-product-banner-info-user__avatar'>
                            {
                              event?.user?.auth?.avatar ? (
                                <img src={event?.user?.auth?.avatar?.url} alt="" />
                              ) : (
                                <i className="icon-user-single"></i>
                              )
                            }
                            </div>
                            <div className='enka-product-banner-info-user__info'>
                              <strong>{event?.user?.auth?.fullname}</strong>
                              <span>{event?.user?.city?.name || ""}</span>
                            </div>
                          </div>
                          <div className='enka-product-banner-info__avis enka-product-banner-info-avis'>
                            <div className='enka-product-banner-info-avis__item'>
                              <i className="icon-favorite-heart"></i> <span>{event?.likes?.length > 0 ? formatNumber(event?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                            </div>
                            <div className='enka-product-banner-info-avis__item'>
                              <i className="icon-chat-two-bubbles-oval"></i> <span>{event?.comments?.length > 0 ? formatNumber(event?.comments?.length) : "Aucun"} commentaire(s)</span>
                            </div>
                          </div>
                        </div>
                        <div className='enka-product-banner-info__orders'>
                          <div className='enka-product-banner-info__price'>
                            <span>Date:</span>
                            <strong>{event?.date_event ? capitalize(format(new Date(event?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</strong>
                          </div>
                          <div className='enka-product-banner-info__actions'>
                            <Button btnType={'button'} label={'Modifier'} btnStyle={'is--primary'} active={true} withIconLeft={true} iconLeftClass='icon-edit-write-2' onClick={() => {navigate(links.edit_event + event?.slug)}}/>
                            <Button btnType={'button'} label={'Supprimer'} btnStyle={'is--danger'} active={true} withIconLeft={true} iconLeftClass='icon-delete-bin' onClick={() => setDeleteModal(true)}/>
                          </div>
                        </div>
                      </div>
                      {
                        pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                          <a className='enka-product-banner__ads' href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                              <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                              <span>publicité</span>
                          </a>
                        ) : null
                      }
                    </div>
                  </div>

                  <div className='enka-product-navigation'>
                    <div className='enka-product-navigation__nav enka-product-navigation-nav'>
                      <button type='button' className={`enka-product-navigation-nav__item ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.my_event + slug + `?tabs=presentation`)}>
                        <span>Description</span>
                      </button>
                      <button type='button' className={`enka-product-navigation-nav__item ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`} onClick={() => navigate(links.my_event + slug + `?tabs=comments`)}>
                        <span>Commentaires ({event?.comments?.length > 0 ? formatNumber(event?.comments?.length) : "Aucun"} commentaire(s))</span>
                      </button>
                    </div>
                    <div className={`enka-product-navigation__container ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`}>
                      <p>{event?.description?.text}</p>
                    </div>
                    <div className={`enka-product-navigation__container ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`}>
                      <div className='enka-product-form'>
                        <div className='enka-product-form__likes enka-product-form-likes'>
                          <button type='button' className={`enka-product-form-likes__btn ${isEditLoading && (typeLike === "like" || typeLike === "unlike") ? "is--disable" : event?.likes?.filter((el:any) => el?.auth?._id === info?._id).length > 0 ? "is--active" : ""}`} onClick={() => {
                            if(event?.likes?.filter((el:any) => el?.auth?._id === info?._id).length > 0){
                              setTypeLike("")
                              dispatch(unlikeEvent({articleId: event?._id}))
                              setTypeLike("unlike")
                            }else{
                              setTypeLike("")
                              dispatch(likeEvent({articleId: event?._id}))
                              setTypeLike("like")
                            }
                          }}>
                            <i className={isEditLoading && (typeLike === "like" || typeLike === "unlike") ? "icon-spin" : "icon-favorite-heart"}></i>
                            <span>{event?.likes ? formatNumber2(event?.likes.length) : "ND"}</span>
                          </button>
                        </div>
                        <div className='enka-product-form__comments enka-product-form-comments'>
                          <div className='enka-product-form-comments__field'>
                            <textarea  placeholder='Donner votre avis' value={commentContent} onChange={(e) => setCommentContent(e.target.value)}/>
                          </div>
                          <div>
                            <button className='enka-product-form-comments__btn' type='button' onClick={() => {
                              if(commentContent){
                                setTypeLike("")
                                dispatch(commentEvent({articleId: event?._id, content: commentContent}))
                                setTypeLike("comment")
                              }
                            }}>
                              <i className={isEditLoading && typeLike === "comment" ? "icon-spin" :"icon-chat-two-bubbles-oval"}></i>
                              <span>Envoyer</span>
                            </button>

                          </div>
                        </div>
                      </div>
                      <div className='enka-product-comments'>
                        <span>Les commentaires</span>
                          {
                            event?.comments?.filter((el: any) => el.supprime === false).sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, readMore).map((item: any, index: number) => (
                              <div className='enka-product-comment' key={index}>
                                <div className='enka-product-comment__user'>
                                  {
                                    item?.user?.auth?.avatar ? (
                                      <img src={item?.user?.auth?.avatar?.url} alt="" />
                                    ) : (
                                      <i className="icon-user-single"></i>
                                    )
                                  }
                                </div>
                                <div className='enka-product-comment__container'>
                                  <strong>{item?.user?.auth?.fullname}</strong>
                                  <p>{item.content}</p>
                                  <span>{capitalize(formatMyDate(item?.createdAt))}</span>
                                  <button type='button' onClick={() => {
                                    setTypeLike("")
                                    dispatch(uncommentEvent({articleId: event?._id, commentId: item?._id}))
                                    setTypeLike("uncomment")
                                  }}>
                                    Supprimer
                                  </button>
                                    
                                </div>
                                
                              </div>
                            ))
                          }
                          {
                             event?.comments?.filter((el: any) => el.supprime === false)?.length > 12 ? (
                              <button className='enka-home-products__more enka-home-products-more' type='button' onClick={() => setReadMore(readMore + 12)}>
                                <div className='enka-home-products-more-line'></div>
                                  <span>Afficher plus</span>
                                <div className='enka-home-products-more-line'></div>
                              </button>
                             ) : null
                          }
                      </div>
                    </div>
                  </div>

                  {
                    isNewNotif ? (
                      <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsNewNotif(false)}>
                        <p>{message || (typeLike === "like" ? "Vous avez aimé cette annonce" : typeLike === "unlike" ? "Vous n'aimez plus cette annonce" : typeLike === "comment" ?  "Votre avis a été envoyé" : typeLike === "cart" ? "Vous avez enregistré dans le panier" : typeLike === "uncart" ? "Vous avez retiré du panier" : "Cet avis a bien été supprimé")}</p>
                      </Toast>
                    ) : null
                  }

                  {
                    deleteModal ? <DeleteModal item={event} closeFnc={setDeleteModal} /> : null
                  }
                </>
                )
              ) : (
                <div className={"kubini-user-error"}>
                    <div className={"kubini-user-error__container"}>
                      <i className="icon-warning-triangle"/>
                      <h2>Element introuvable</h2>
                      <div className={"kubini-user-error__action"}>
                        <Button btnType={"button"} label={"Revenir vers le profil"} btnStyle={"is--primary"} active={true} onClick={() => navigate(links.account)}/>
                      </div>
                    </div>
                </div>
              )
            )
          )
        }
      </div>
    </MainLayout>
  )
}
