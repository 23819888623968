import React, { useEffect, useState } from 'react'

interface TostParams {
    id: any, 
    status: "st-success" | "st-danger" | "st-info" | "st-warning", 
    fnc: (val?: any) => void, 
    children: React.ReactNode
}


export const Toast:React.FC<TostParams> = ({id, fnc, status, children}) => {
    const [wait, setWait] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setWait(false)
            fnc(false)
        }, 5000);
        return () => clearTimeout(timer);
    })
    return (
        <div className={`kubini-toast ${wait ? "is--open" : "is--close"}`} id={id}>
            <div className={`kubini-toast-contain ${status}`}>
                <div className='kubini-toast-contain__icon'>
                {
                    status === "st-info" ? (
                        <i className=" icon-question-square"/>
                    ) : (
                        status === "st-danger" ? (
                            <i className="icon-block"/>
                        ) : (
                            status === "st-success" ? (
                                <i className="icon-party-popper"/>
                            ) : (
                                status === "st-warning" ? (
                                    <i className="icon-warning-triangle"/>
                                ) : null
                            )
                        )
                    )
                }
                </div>
                <div className='kubini-toast-contain__container'>
                    {children}
                </div>
            </div>
        </div>
    )
}
