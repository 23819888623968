import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export function NotificationPage () {
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.notif}`)
    }
  }, [token, navigate]);
  console.log(info)
  return (
    <MainLayout title='Boite de reception'>
        <div></div>
    </MainLayout>
  )
}
