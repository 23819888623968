import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { UserAuthContext } from '../../../../context/AuthContext'
import { links } from '../../../../routes'
import { activeMyAccount } from '../../../../../redux/feature/auth/auth.slice'

export function ActiveAccountPage() {
  const {username, token} = useParams()
  let navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.auth);
  const { token: tokens } = useContext(UserAuthContext);

  useEffect(() => {
      if(tokens){
          navigate(links.home)
      }
  }, [tokens, navigate]);

  useEffect(() => {
    if(username && token){
      dispatch(activeMyAccount({username, token}))
    }else{
      navigate(links.errors)
    }
  }, [dispatch, navigate, username, token])


  if(isCreateLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }

  if(isCreateError){
    return (
        <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>{message}</h2>
          </div>
        </div>
    )
  }

  return (
    <>
    {
      isCreateSuccess ? (
        <MainLayout title='Activication du compte'>
          <div className='kubini-page-success'>
            <div className='kubini-page-success__container'>
              <i className="icon-validation-check"></i>
              <h2>Compte activé</h2>
              <p>Bienvenue sur Enka-Market. Votre compte a été activée</p>
            </div>
          </div>
        </MainLayout>
      ) : null
    }
    </>
  )
}
