import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import { getEvent, reset, updateEvent } from '../../../../../redux/feature/event/event.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { format } from 'date-fns';
import { AlertModule, Button, ModalLayout, Textfield } from '../../../../components';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';
import { APIURL } from '../../../../../redux/utils';
import { slugifyFileName } from '../../../../../utils/function';

const EditContain = ({item}:{item: any}) => {
  const { info } = useContext(UserAuthContext);
  const {isEditLoading, isEditError, isEditSuccess, event, message} = useAppSelector((state) => state.event);
  const [images, setImages] = useState<any[]>([]);
  const [imagesUrl, setImagesUrl] = useState<any[]>([]);
  const [imagesPUrl, setImagesPUrl] = useState<any[]>([]);
  const [imagesPreview, setImagesPreview] = useState<any[]>([]);
  const [imagesToSend, setImagesToSend] = useState<any[]>([]);
  const [imagesOldUrl, setImagesOldUrl] = useState<any[]>(item?.images);

  const fetchPresignedUrl = async () => {
    if(images.length > 0){
      images.forEach(async (el: any) => {
        const response = await fetch(`${APIURL}presigned-url/put?folder=${`evenement-de-`+info?.username}&id=${el.name}&expiry=86400`);
        const data = await response.json();
        setImagesPUrl((list) => [...list, {url: data.url, ...el}])
      })
      
    }else{
      setError({
        type: "global",
        message: "Veuillez renseigner une image"
      })
      return
    }
  };
  
  const [data, setData] = useState<any>({
    title: item?.title, 
    date_event: item?.date_event ? format(new Date(item?.date_event), "yyyy-MM-dd") : "", 
    description: item?.description?.text, 
    lieu: item?.lieu,
    id: item?._id
  });
  let navigate = useNavigate()
  const dispatch = useAppDispatch();

  const [openC, setOpenC] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [sendIt, setSendIt] = useState<boolean>(false);

  const [error, setError] = useState<{ type: string, message: string }>({
    type: "",
    message: ""
  })

  const handleMultiChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
       let tabs: File[] = Object.values(file)
        if(tabs.length < 5){
            tabs?.forEach((el, index) => {
                let type: number = el.size
                let max: number = 1024 * 1024 * 4
                if(type < max){
                  let info = {
                    name: slugifyFileName(el.name),
                    size: el.size,
                    type: el.type
                  }
                  if(imagesUrl.filter((el: any) => el === info.name).length < 1){
                    setImagesUrl((list) => [...list, info.name])
                    setImages((list) => [...list, {name: info.name, file: el, progress: 0, status: "normal"}])
                    setImagesPreview(list => [...list, URL.createObjectURL(el)])
                  }
                }else{
                    setError({
                        type: "global",
                        message: "Votre fichier ne doit pas dépasser 4Mo"
                    })
                    return
                }
            })
            setError({
                type: "",
                message: ""
            })
        }else{
            setError({
                type: "multi-image",
                message: "Vous ne devez pas dépasser 4 images"
            })
        }
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.title){
        setError({
            type: "title",
            message: "Veuillez renseigner le titre"
        })
        return
    }
    if(!data.description){
        setError({
            type: "description",
            message: "Veuillez renseigner la description"
        })
        return
    }
    if(!data.date_event){
        setError({
            type: "date_event",
            message: "Veuillez renseigner la sous-categorie"
        })
        return
    }
    if(!data.lieu){
        setError({
            type: "lieu",
            message: "Veuillez choisir la catégorie"
        })
        return
    }
    if((images.length + imagesOldUrl.length) < 1){
        setError({
            type: "global",
            message: "Veuillez renseigner au moins une image de capture d'écran"
        })
        return
    }
    if((images.length + imagesOldUrl.length) > 4){
        setError({
          type: "global",
          message: "Veuillez renseigner 4 images maximum de capture d'écran"
        })
        return
    }
    setOpenC(true)

    fetchPresignedUrl()
  }

  useEffect(() => {
    if(openC){
      if(imagesPUrl.length === images.length){
        if(imagesPUrl.length > 0){
          imagesPUrl.forEach(async (el: any, index: number) => {
            const fileUrl = el?.file
            const putUrl = el?.url
            const response = await fetch(putUrl, {
              method: 'PUT',
              body: fileUrl,
            });
            if (response.ok) {
              const photoUrl = putUrl.split('?')[0];
              setImagesToSend((list) => [...list, {
                url: photoUrl,
                name: el.name,
                size: el.size,
                type: "image"
              }])

              if(index === (images.length - 1)){
                setSendIt(true)
              }

            } else {
              setError({
                type: "global",
                message: "Une erreur est survenue lors de l'enregistrement de l'images"
              })
              return
            }
          })
        }
      }else{
        setError({
          type: "global",
          message: "Une erreur est survenue"
        })
      }
    }
  }, [imagesPUrl, openC, images])

  useEffect(() => {

    if(sendIt){
      if(imagesToSend.length === images.length){
        dispatch(updateEvent({
          ...data,
          images: [...imagesOldUrl, ...imagesToSend ]
        }))
      }
    }
  }, [data, dispatch, imagesToSend, sendIt, images, imagesToSend, imagesOldUrl])

  useEffect(() => {
    if(isEditSuccess && event){
      setOpenM(true)
      setOpenC(false)
      setSendIt(false)
    }
  }, [isEditSuccess, event])


  return (
    <>

      <form className='enka-home-create__container' onSubmit={handleSubmit}>
        {
          isEditError ? (
            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
          ) : null
        }
        <div className={"kbn-mulit-file"}>
            <input type="file" accept='.jpg, .png,.jpeg' multiple id='imagesImg' onChange={handleMultiChangeFile}/>
            <div className='kbn-mulit-file__grid'>
              {
                  (imagesOldUrl?.length + images.length) > 0 ? (
                    (imagesOldUrl?.length + images.length) > 3 ? (
                      <>
                      {
                        images.map((image, index) => (
                          <div className={"kbn-mulit-file-preview"} key={index}>
                              <img src={imagesPreview[index]} alt="article"/>
                              <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                setImages(list => [...list.filter(el => el !== image)])
                              }}>
                                  <i className="icon-delete-bin"></i>
                              </button>
                          </div>
                        ))
                      }
                      {
                        imagesOldUrl.map((image, index) => (
                          <div className={"kbn-mulit-file-preview"} key={index}>
                              <img src={image?.url} alt="article"/>
                              <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                setImagesOldUrl(list => [...list.filter(el => el !== image)])
                              }}>
                                  <i className="icon-delete-bin"></i>
                              </button>
                          </div>
                        ))
                      }
                      </>
                      ) : (
                          <>
                              {
                                images.map((image, index) => (
                                  <div className={"kbn-mulit-file-preview"} key={index}>
                                      <img src={imagesPreview[index]} alt="article"/>
                                      <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                        setImagesUrl(list => [...list.filter(el => el !== imagesUrl[index])])
                                        setImagesPreview(list => [...list.filter(el => el !== imagesPreview[index])])
                                        setImages(list => [...list.filter(el => el !== image)])
                                      }}>
                                          <i className="icon-delete-bin"></i>
                                      </button>
                                  </div>
                                ))
                              }
                              {
                                imagesOldUrl.map((image, index) => (
                                  <div className={"kbn-mulit-file-preview"} key={index}>
                                      <img src={image?.url} alt="article"/>
                                      <button className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                        setImagesOldUrl(list => [...list.filter(el => el !== image)])
                                      }}>
                                          <i className="icon-delete-bin"></i>
                                      </button>
                                  </div>
                                ))
                              }
                              <label htmlFor="imagesImg" className={"kbn-mulit-file-preview_btn"}>
                                  <i className="icon-add-square"></i>
                                  <span>Vous pouvez ajouter {4 - imagesUrl.length} image(s) si vous le voulez</span>
                              </label>
                          </>
                      )
                  ) : (
                      <label htmlFor="imagesImg" className={"kbn-mulit-file-btn"}>
                          <i className=" icon-picture-gallery"></i>
                          {
                            error?.type === "multi-image" ? (
                                <span style={{color: "red"}}>{error?.message}</span>
                            ) : (
                                <span>Cliquer pour changer des images de l'annonce (4 max - 4Mo max par image) </span>
                            )
                          }
                      </label>
                  )
              }
            </div>
              
        </div>
        <div className='enka-home-create__fields'>
          <div className='enka-home-create__field'>
            <Textfield
                id={"title"}
                value={data.title}
                require={true}
                changeValue={(val: string) => setData({...data, title: val})}
                type={"text"}
                label={"Titre de l'annonce"}
                error={error}
                placeholder={"Veuillez insérer le titre"}
            />
          </div>
          <div className='enka-home-create__field'>
            <Textfield
                id={"date_event"}
                value={data.date_event}
                require={true}
                changeValue={(val: string) => setData({...data, date_event: val})}
                type={"date"}
                label={"Date de l'évènement"}
                error={error}
                placeholder={"Veuillez insérer la date"}
            />
          </div>
          <div className='enka-home-create__field'>
            <Textfield
                id={"lieu"}
                value={data.lieu}
                require={true}
                changeValue={(val: string) => setData({...data, lieu: val})}
                type={"text"}
                label={"Lieu de l'évènement"}
                error={error}
                placeholder={"Veuillez insérer le lieu"}
            />
          </div>
          <div className='enka-home-create__field'>
            <Textfield
                id={"description"}
                value={data.description}
                require={true}
                changeValue={(val: string) => setData({...data, description: val})}
                type={"textarea"}
                label={"Description de l'annonce"}
                error={error}
                placeholder={"Veuillez insérer la description"}
            />
          </div>
        </div>
        <div className='enka-home-create__action'>
          <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass={"icon-spin"}/>
        </div>
      </form>

      <ModalLayout size={"is--md"} id={"add"} fnc={() => {}} withClose={false} isOpen={openC}>
          <div className={"kubini-modal-form-status"}>
            <div className={"kubini-modal-form-status__container"}>
                <div className={"kubini-modal-form-status__icon is--success"}>
                    <i className="icon-validation-check"/>
                </div>
                <div className={"kubini-modal-form-status__text"}>
                    <h2>Modification d'une annonce</h2>
                    <p>Les modifications ont bien été enregistrées</p>
                    <div className={"kubini-modal-form-status__action"}>
                        <Button
                            btnType={"button"}
                            label={"Revenir dans mon compte..."}
                            btnStyle={"is--neutral"}
                            active={true}
                            withIconLeft={false}
                            onClick={() => {
                              navigate(links.account+"?tabs=evenement")
                            }}
                        />
                    </div>
                </div>
            </div>
          </div>
      </ModalLayout>
    </>
  )
}

export const EventEditPage = () => {
  const {slug} = useParams()
  const { token } = useContext(UserAuthContext);
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  const {isLoading: isALoading, isSuccess: isASuccess, isError: isAError, event, message: aMessage} = useAppSelector((state) => state.event);
  const dispatch = useAppDispatch();
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.edit_event + slug}`)
    }
  }, [token, navigate, slug]);
  useEffect(() => {
    if(slug){
      dispatch(getEvent(slug))
      dispatch(getPubs({}))
    }


    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch, slug])

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.edit_article + slug}`)
    }
  }, [token, navigate, slug]);
  

 

  if(isLoading || isALoading){
    return (
      <div className={"kubini-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError || isAError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message || aMessage}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }
  
  return (
    <MainLayout title='Events'>
      <div className='enka-home'>
        <div className='enka-home__header'>
          <h1>Modification d'une annonce</h1>
        </div>
        <div className='enka-home__create enka-home-create'>
          {isASuccess && event ? <EditContain item={event}/> : null}
          <div className='enka-home-create__side'>
            {
              isSuccess && pubs ? (
                pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                  <div  className='enka-ads is--side' >
                    <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                    <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                    <span>publicité</span>
                    </a>
                  </div>
                ) : null
              ) : null
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
