import React from 'react';
import {Routes, Route} from "react-router-dom";
import {routes} from "../constant";

export const WebRouter = () => {
    return (
        <Routes>
            {
                routes.map(({Component, path}, index) => (
                    <Route path={path} element={<Component/>} key={index}/>
                ))
            }
        </Routes>
    );
};
