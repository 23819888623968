import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/store';
import { links } from '../../../../routes';
import { formatMyDate, formatNumber, formatNumber2, truncate } from '../../../../../utils/function';

export const ArticleContainer = ({type, valeurs, search, categories, subcategories}:{type: string, valeurs: number[],search: string, categories: string[], subcategories: string[]}) => {
    const {isLoading, isError, isSuccess, articles, message} = useAppSelector((state) => state.annonce);
    let navigate = useNavigate()
    const [readMore, setReadMore] = useState<number>(32)
    
    return (
        <>
        {
            isLoading ? (
                <><i className="icon-spin"></i> <span>Chargement...</span></>
            ) : (
                isError ? (
                <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
                ) : (
                isSuccess && articles ? (
                    articles
                    .filter((el: any) => el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                    .filter((el: any) => type !== "all" ? subcategories.filter((ele: string) => ele === el.subcategory.slug).length > 0 || categories.filter((ele: string) => ele === el.subcategory.category.slug).length > 0 : true)
                    .filter((el: any) => (valeurs[0] <= el.price &&  valeurs[1] >= el.price))
                    .length > 0 ? (
                    <div className='enka-home-grid-section'>
                        <div className='enka-home-grid-section__header enka-home-grid-section-header'>
                            <h2>Annonces</h2>
                        </div>
                        <div className='enka-home-grid-grid'>
                        {
                            articles
                            .filter((el: any) => el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                            .filter((el: any) => type !== "all" ? subcategories.filter((ele: string) => ele === el.subcategory.slug).length > 0 || categories.filter((ele: string) => ele === el.subcategory.category.slug).length > 0 : true)
                            .filter((el: any) => (valeurs[0] <= el.price &&  valeurs[1] >= el.price))
                            .slice(0, readMore)
                            .map((item: any, index: number) => (
                            <div className='enka-home-grid-item' key={index}>
                                <button className='enka-card' type='button' onClick={() => navigate(links.article + item?.slug)}>
                                    {
                                        item.epingle ? (
                                        <div className='enka-card__important'>
                                            <i className="icon-pin-2"></i>
                                        </div>
                                        ) : null
                                    }
                                    <div className='enka-card-image'>
                                        {
                                            item?.images?.length > 0 ? (
                                                item?.images[0]?.url ? (
                                                <img src={item?.images[0]?.url} alt="cover" />
                                                ) : (
                                                <i className="icon-picture-flower"></i>
                                                )
                                            ) : (
                                                <i className="icon-picture-flower"></i>
                                            )
                                        }
                                    </div>
                                    <div className='enka-card-info'>
                                        <div className='enka-card-info-id'>
                                        <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                        <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                        <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                                        </div>

                                        <div className='enka-card-info-stats'>
                                            <div className='enka-card-info-user'>
                                                <div className='enka-card-info-user__avatar'>
                                                    {
                                                        item?.user?.auth?.avatar ? (
                                                            <img src={item?.user?.auth?.avatar?.url} alt="" />
                                                        ) : (
                                                            <i className="icon-user-single"></i>
                                                        )
                                                    }
                                                </div>
                                                <div className='enka-card-info-user__info'>
                                                    <strong>{item?.user?.auth?.fullname}</strong>
                                                    <span>{item?.user?.city?.name || ""}</span>
                                                </div>
                                            </div>
                                            <div className='enka-card-info-stat'>
                                                <div className='enka-card-info-stat__item'>
                                                    <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                                </div>
                                                <div className='enka-card-info-stat__item'>
                                                    <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                                </div>
                                            </div>
                                            <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            ))
                        }
                        </div>
                        {
                            articles
                            .filter((el: any) => el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                            .filter((el: any) => type !== "all" ? subcategories.filter((ele: string) => ele === el.subcategory.slug).length > 0 || categories.filter((ele: string) => ele === el.subcategory.category.slug).length > 0 : true)
                            .filter((el: any) => (valeurs[0] <= el.price &&  valeurs[1] >= el.price))
                             .length > 32 ? (
                              <button className='enka-home-products__more enka-home-products-more' type='button' onClick={() => setReadMore(readMore + 24)}>
                                <div className='enka-home-products-more-line'></div>
                                  <span>Afficher plus</span>
                                <div className='enka-home-products-more-line'></div>
                              </button>
                             ) : null
                        }
                    </div>
                    ) : (<strong>Aucune annonce trouvée</strong>)
                ) : null
                )
            )
        }
        </>
    )
}
