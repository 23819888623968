import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { Button, Illustration1, ModalLayout } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { reset as areset, getMyArticles } from '../../../../../redux/feature/annonce/annonce.slice';
import { reset as ereset, getMyEvents } from '../../../../../redux/feature/event/event.slice';
import { getMyPosts, reset as preset } from '../../../../../redux/feature/post/post.slice';
// import { getPubs, reset } from '../../../../../redux/feature/pub/pub.slice';
import { capitalize, formatMyDate, formatNumber, formatNumber2, truncate } from '../../../../../utils/function';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const AnnoncesContain = () => {
  const {isLoading, isError, isSuccess, articles, message} = useAppSelector((state) => state.annonce);
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()

  return (
    <>
      {
        isLoading ? (
          <div className={"kubini-loader"}>
            <i className="icon-spin"/>
          </div>
        ) : (
          isError ? (
            <div className={"kubini-user-error"}>
              <div className={"kubini-user-error__container"}>
                <i className="icon-warning-triangle"/>
                <h2>Oupss!! Une erreur est survenue</h2>
                <p>{message}</p>
                <div className={"kubini-user-error__action"}>
                  <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                </div>
              </div>
            </div>
          ) : (
            isSuccess && articles ? (
              <>
                {
                  articles?.length > 0 ? (
                    <div className='enka-home-account-grid'>
                      {
                        articles.slice(0, limit).map((item: any, index: number) => (
                          <div className='enka-home-account-grid-item' key={index}>
                            <button className='enka-card' type='button' onClick={() => navigate(links.my_article + item?.slug)}>
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                  <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                                </div>
                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                            </button>
                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    <strong>Aucun element enregistré...</strong>
                  )
                }
                {
                  articles.length > 12 ? (
                    <div className='enka-home-account-action'>
                      <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                    </div>
                  ) : null
                }
              </>
            ) : null
          )
        )
      }
    </>
  )
}

const EvensContain = () => {
  const {isLoading, isError, isSuccess, events, message} = useAppSelector((state) => state.event);
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()
  return (
    <>
      {
        isLoading ? (
          <div className={"kubini-loader"}>
            <i className="icon-spin"/>
          </div>
        ) : (
          isError ? (
            <div className={"kubini-user-error"}>
              <div className={"kubini-user-error__container"}>
                <i className="icon-warning-triangle"/>
                <h2>Oupss!! Une erreur est survenue</h2>
                <p>{message}</p>
                <div className={"kubini-user-error__action"}>
                  <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                </div>
              </div>
            </div>
          ) : (
            isSuccess && events ? (
              <>
                {
                  events?.length > 0 ? (
                    <div className='enka-home-account-grid'>
                      {
                        events.slice(0, limit).map((item: any, index: number) => (
                          <div className='enka-home-account-grid-item' key={index}>
                            <button className='enka-card' type='button' onClick={() => navigate(links.my_event + item?.slug)}>
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.date_event ? capitalize(format(new Date(item?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</h2>
                                  
                                  <span>{"Lieu :" + item?.lieu?.toUpperCase()}</span>
                                </div>
                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                            </button>
                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    <strong>Aucun element enregistré...</strong>
                  )
                }
                {
                  events.length > 12 ? (
                    <div className='enka-home-account-action'>
                      <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                    </div>
                  ) : null
                }
              </>
            ) : null
          )
        )
      }
    </>
  )
}

const PostContain = () => {
  const {isLoading, isError, isSuccess, posts, message} = useAppSelector((state) => state.post);
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()
  return (
    <>
      {
        isLoading ? (
          <div className={"kubini-loader"}>
            <i className="icon-spin"/>
          </div>
        ) : (
          isError ? (
            <div className={"kubini-user-error"}>
              <div className={"kubini-user-error__container"}>
                <i className="icon-warning-triangle"/>
                <h2>Oupss!! Une erreur est survenue</h2>
                <p>{message}</p>
                <div className={"kubini-user-error__action"}>
                  <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                </div>
              </div>
            </div>
          ) : (
            isSuccess && posts ? (
              <>
                {
                  posts?.length > 0 ? (
                    <div className='enka-home-account-grid'>
                      {
                        posts.slice(0, limit).map((item: any, index: number) => (
                          <div className='enka-home-account-grid-item type2' key={index}>
                            <button className='enka-post' type='button' onClick={() => navigate(links.my_post + item?.slug)}>
                              <div className='enka-post-info'>
                                <div className='enka-card-info-id'>
                                  <h2>{item?.titre ? truncate(item?.titre, 80) : "ND"}</h2>
                                  <p>{item?.description?.text  ? truncate(item?.description?.text, 80) : "ND"} </p>
                                </div>
                                <div className='enka-post-info-stats'>
                                  <div className='enka-post-info-stat'>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-post-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                            </button>
                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    <strong>Aucun element enregistré...</strong>
                  )
                }
                {
                  posts.length > 12 ? (
                    <div className='enka-home-account-action'>
                      <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                    </div>
                  ) : null
                }
              </>
            ) : null
          )
        )
      }
    </>
  )
}

export const AccountPage = () => {
  const { info,logOut, token } = useContext(UserAuthContext);
  const dispatch = useAppDispatch();
  // const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  let navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get('tabs')

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account}`)
    }
  }, [token, navigate]);
  useEffect(() => {
    dispatch(getMyArticles({}))
    dispatch(getMyEvents({}))
    dispatch(getMyPosts({}))
    // dispatch(getPubs({}))

    return () => {
      // dispatch(reset())
      dispatch(preset())
      dispatch(areset())
      dispatch(ereset())
    }
  }, [dispatch])

  return (
    <MainLayout title='Mon Compte'>
      <div className='enka-home'>
        
        <div className='enka-home__account enka-home-account'>
          <div className='enka-home-account__user enka-home-account-user'>
            <div className='enka-home-account-user__container'>
                <div className='enka-home-account-user__avatar'>
                  {
                    info?.avatar ? (
                      <img src={info?.avatar?.url} alt="" />
                    ) : (
                      <i className="icon-user-single"></i>
                    )
                  }
                </div>
                <div className='enka-home-account-user__info'>
                    <h2>{info?.fullname}</h2>
                    <span>{`@${info?.username}`}</span>
                    <p>{info?.user?.city?.name || ""}</p>
                </div>
                <div className='enka-home-account-user__actions'>
                  <div className='enka-home-account-user__action'>
                    <Button btnType={'button'} label={'Publier une annonce'} btnStyle={'is--primary'}  iconLeftClass='icon-add-square' withIconLeft={true} active={true} onClick={() => setOpenMenu(true)}/>
                  </div>
                  <div className='enka-home-account-user__action'>
                    <Button btnType={'button'} label={'Paramètres'} iconLeftClass='icon-user-circle' withIconLeft={true} btnStyle={'is--neutral'} active={true} onClick={() => navigate(links.setting_info)}/>
                  </div>
                  <div className='enka-home-account-user__action'>
                    <Button btnType={'button'} label={'Se deconnecter'} btnStyle={'is--danger'}  iconLeftClass='icon-logout' withIconLeft={true} active={true} onClick={() => logOut()}/>
                  </div>
                </div>
            </div>
          </div>
          <div className='enka-home-account__container enka-home-account-container'>
            <div className='enka-home-account-navigation'>
                <div className='enka-home-account-navigation__content'>
                  <button type='button' className={`enka-home-account-navigation__item ${tabs ? (tabs === "annonce" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.account+"?tabs=annonce")}>
                    <span>Annonces</span>
                  </button>
                  <button type='button' className={`enka-home-account-navigation__item ${tabs === "evenement" ? "is--active" : ""}`} onClick={() => navigate(links.account+"?tabs=evenement")}>
                    <span>Evèmenents</span>
                  </button>
                  <button type='button' className={`enka-home-account-navigation__item ${tabs === "publication" ? "is--active" : ""}`} onClick={() => navigate(links.account+"?tabs=publication")}>
                    <span>Publications</span>
                  </button>
                </div>
                
            </div>
            <div className={`enka-home-account-navigation__container ${tabs ? (tabs === "annonce" ? "is--active" : "") : "is--active"}`}>
              <AnnoncesContain/>
            </div>
            <div className={`enka-home-account-navigation__container ${tabs === "evenement" ? "is--active" : ""}`}>
              <EvensContain/>
            </div>
            <div className={`enka-home-account-navigation__container ${tabs === "publication" ? "is--active" : ""}`}>
              <PostContain/>
            </div>
          </div>
        </div>

        <ModalLayout size={"is--md"} id={"add"} fnc={() => setOpenMenu(false)} withClose={true} isOpen={openMenu}>
          <div className="kubini-modal-auth">
            <div className="kubini-modal-auth__illus">
              <Illustration1/>
            </div>
            <div className="kubini-modal-auth__container">
              <div>
                <h2>Bienvenue sur Enka-market</h2>
              </div>
              <div className="kubini-modal-auth__actions">
                <Button btnType={"button"} label={"Faire un post"} btnStyle={"is--neutral"} active={true} onClick={() => navigate(links.ajout_post)}/>
                <Button btnType={"button"} label={"Vendre un produit"} btnStyle={"is--primary"} active={true} onClick={() => navigate(links.ajout_article)}/>
                <Button btnType={"button"} label={"Publier un annonce d'evenement"} btnStyle={"is--secondary"} active={true} onClick={() => navigate(links.ajout_event)}/>
              </div>
            </div>
          </div>
        </ModalLayout>
      </div>
    </MainLayout>
  )
}
