import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { SettingModule } from '../moule/SettingModule';
import { getCitiesOption, reset, updateAvatar, updateMyInformation } from '../../../../../../redux/feature/auth/auth.slice';
import { AlertModule, Button, Selectfield, Textfield, Toast } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { APIURL } from '../../../../../../redux/utils';
import { slugifyFileName } from '../../../../../../utils/function';


const AvatarEdit = () => {
  let folder: string = "avatar"
  const [avatars, setAvatars] = useState<any>(null);
  const [putUrl, setPutUrl] = useState("");
  const [infoImage, setInfoImage] = useState<any>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<any>(null)
  const dispatch = useAppDispatch();

  const { isAvatarLoading, isAvatarError ,message } = useAppSelector((state) => state.auth);
  const { info } = useContext(UserAuthContext);

  const fetchPresignedUrl = async () => {
    if(avatars){
      const response = await fetch(`${APIURL}presigned-url/put?folder=${folder}&id=${info?.username}-${avatars.name}&expiry=86400`);
      const data = await response.json();
      setPutUrl(data.url)
      return data.url;
    }else{
      setError({
        field: "global",
        message: "Veuillez renseigner une images"
      })
      return
    }
  };
  
  const handleCoverChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
          let type: number = file[0].size
          
          let max: number = 1024 * 1024 * 4
          if(type < max){
            let info = {
              name: slugifyFileName(file[0].name),
              size: file[0].size,
              type: file[0].type,
              file: file[0]
            }
            setAvatars(info)
          }else{
              setError({
                field: "global",
                message: "Votre fichier ne doit pas dépasser 4MB"
              })
              return
          }
      }
  }

  
  const handleFileUpload = async () => {
    const file = avatars?.file
    const response = await fetch(putUrl, {
      method: 'PUT',
      body: file,
    });
    if (response.ok) {
      const photoUrl = putUrl.split('?')[0];
      setInfoImage({
        url: photoUrl,
        size: avatars?.size,
        name: avatars?.name,
        type: "image"
      })
      
    } else {
      setError({
        field: "global",
        message: "Une erreur est survenue lors de l'enregistrement de l'images"
      })
      return
    }
  };
 
  useEffect(() => {
    if(avatars){
      fetchPresignedUrl()
    }
  }, [avatars]);

  useEffect(() => {
    if(putUrl){
      handleFileUpload()
    }
  }, [putUrl]);

  useEffect(() => {
    if(infoImage){
      let data = {
        image : infoImage
      }
      dispatch(updateAvatar(data))
      setError(null)
      setAvatars("")
    }
  }, [infoImage, dispatch]);

  useEffect(() => {
    if(error){
      setOpenModal(true)
    }
  }, [error]);

  useEffect(() => {
    if(isAvatarError){
      setOpenModal(true)
    }
  }, [isAvatarError]);

  return (
      <>
          <div className={"enka-setting-avatar"}>
            <div className='enka-setting-avatar__container'>
              <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleCoverChangeFile}/>
              <label className={"enka-setting-avatar-image"} htmlFor={"imageImg"}>
                  {
                    isAvatarLoading ? (
                      <i className="icon-spin"/>
                    ) : (
                      infoImage ? (
                        <img src={infoImage?.url} alt="imageUrl"/>
                      ) : (
                        info?.avatar ? (
                          <img src={info?.avatar?.url} alt="imageUrl"/>
                        ): ( 
                          <i className="icon-user-single"></i>
                        )
                      )
                    )
                  }
              </label>
            </div>
            <div className='enka-setting-avatar__actions'>
              <label htmlFor="imageImg" className='kubini-button is--neutral'>
                <i className="icon-camera"></i>
                <span>Modifier la photo</span>
              </label>
              <button type='button' className='kubini-button is--danger' onClick={() => {
                let data = {image : null}
                dispatch(updateAvatar(data))
              }}>
                <i className="icon-delete-bin"></i>
                <span>Supprimer la photo</span>
              </button>
            </div>
             {
                openModal && (
                  <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
                    <p>{message || error?.message}</p>
                  </Toast>
                )
             }
          </div>
      </>

  )
}

const InformationEdit = ({info, cities}:{info: any, cities: any[]}) => {
  const [data, setData] = useState({
    fullname: info?.fullname || "", 
    telephone: info?.telephone || "", 
    username: info?.username || "", 
    cityId: info?.user?.city?._id || "", 
    social: info?.social || null
  })

  const dispatch = useAppDispatch();
  const { isEditLoading, isEditSuccess, isEditError ,message } = useAppSelector((state) => state.auth);
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(!data.fullname){
      setError({
          field: "fullname",
          message: "Veuillez renseigner ce champs"
      })
      return
    }

    if(!data.username){
        setError({
            field: "username",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.telephone){
        setError({
            field: "telephone",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.cityId){
        setError({
            field: "cityId",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    dispatch(updateMyInformation(data))
  }

  return (
    <div className='enka-home-account__form enka-home-account-form'>
      <h2>Mes informations</h2>
      <form className='enka-home-account-form__container' onSubmit={handleSumbit}>
        {
          isEditSuccess ? (
            <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
          ) : (
              isEditError ? (
                  <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
          )
        }
        <div className='enka-home-account-form__fields'>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"nom"}
                value={data.fullname}
                require={true}
                changeValue={(val: string) => setData({...data, fullname: val})}
                type={"text"}
                label={"Nom et prenom(s)"}
                error={error}
                placeholder={"Veuillez insérer votre nom et prenom(s)"}
              />
          </div>
          <div className='enka-home-account-form__field'>
            <Textfield
              id={"username"}
              value={data.username}
              require={true}
              changeValue={(val: string) => setData({...data, username: val})}
              type={"username"}
              label={"Nom d'utilisateur"}
              error={error}
              placeholder={"Veuillez insérer votre nom d'utilisateur"}
            />
          </div>
          <div className='enka-home-account-form__field'>
            <Textfield
                id={"telephone"}
                value={data.telephone}
                require={true}
                changeValue={(val: string) => setData({...data, telephone: val})}
                type={"phone"}
                label={"Telephone"}
                error={error}
                placeholder={"Veuillez insérer votre telephone"}
            />
          </div>
          <div className={"enka-home-account-form__field"}>
            <Selectfield
                  id={"cityId"}
                  value={data.cityId}
                  require={true}
                  changeValue={(value: any) => setData({...data, cityId: value})}
                  tabs={cities}
                  label={"Ville de residence"}
                  placeholder={"ex: Bobo"}
                  error={error}
              />
          </div>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"facebook"}
                value={data.social?.facebook}
                require={false}
                changeValue={(val: string) => setData({...data, social: {facebook: val}})}
                type={"text"}
                label={"Facebook"}
                error={error}
                placeholder={"Veuillez insérer l'url de votre page facebook"}
              />
          </div>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"whatsapp"}
                value={data.social?.whatsapp}
                require={false}
                changeValue={(val: string) => setData({...data, social: {whatsapp: val}})}
                type={"phone"}
                label={"Numero whatsapp"}
                error={error}
                placeholder={"Veuillez insérer  votre numero whatsapp (ex: 22671234567)"}
              />
          </div>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"instagram"}
                value={data.social?.instagram}
                require={false}
                changeValue={(val: string) => setData({...data, social: {instagram: val}})}
                type={"text"}
                label={"Instagram"}
                error={error}
                placeholder={"Veuillez insérer l'url de votre page instagram"}
              />
          </div>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"telegram"}
                value={data.social?.telegram}
                require={false}
                changeValue={(val: string) => setData({...data, social: {telegram: val}})}
                type={"text"}
                label={"Telegram"}
                error={error}
                placeholder={"Veuillez insérer l'url de votre chaine telegram"}
              />
          </div>
          <div className='enka-home-account-form__field'>
              <Textfield
                id={"twitter"}
                value={data.social?.twitter}
                require={false}
                changeValue={(val: string) => setData({...data, social: {twitter: val}})}
                type={"text"}
                label={"X (Twitter)"}
                error={error}
                placeholder={"Veuillez insérer l'url de votre page X(ancien twitter)"}
              />
          </div>
        </div>
        <div className='enka-home-account-form__action'>
          <Button btnType={"submit"} label={"Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass='icon-spin'/>
        </div>
      </form>
    </div>
  )
}

export const SettingInformationPage = () => {
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting_info}`)
    }
  }, [token, navigate]);
  const dispatch = useAppDispatch();
  const { message, cities, isLoading, isError, isSuccess } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCitiesOption({}))

    return () => {
        dispatch(reset())
    }
  }, [dispatch])


  return (
    <MainLayout title='Mes informations'>
      <div className='enka-home'>
        <div className='enka-home__account enka-home-account'>
          <SettingModule/>
          <div className='enka-home-account__container enka-home-account-container'>
            {
              isLoading ? (
                <div className={"kubini-loader"}>
                  <i className="icon-spin"/>
                </div>
              ) : (
                isError ? (
                  <div className={"kubini-page-error"}>
                    <div className={"kubini-page-error__container"}>
                      <i className="icon-warning-triangle"/>
                      <h2>{message}</h2>
                    </div>
                  </div>
                ) : (
                  isSuccess && cities && info ? (
                    <div className='enka-home-account__forms enka-home-account-forms'>
                      <AvatarEdit/>
                      <InformationEdit info={info} cities={cities}/>
                    </div>
                  ) : null
                )
              )
              
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

