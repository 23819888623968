import React from 'react'
import { links } from '../../../../../routes'
import { NavLink } from 'react-router-dom'

export const SettingModule = ()  => {
    const list = [
        {
            link: links.setting_info,
            label: "Mes informations",
            icon: "icon-user-circle"
        },
        {
            link: links.setting_password,
            label: "Mot de passe et Securité",
            icon: "icon-security-shield-profile"
        },
        {
            link: links.setting_feedback,
            label: "Feedbacks",
            icon: "icon-mail-chat-bubble-oval"
        },
    ]
  return (
    <div className='enka-home-account__setting enka-home-account-setting'>
        <div className='enka-home-account-setting__container'>
            <h2>Paramètres du compte</h2>
            {
                list.map((item: any, index: number) => (
                    <div className='enka-home-account-setting__item' key={index}>
                        <NavLink to={item.link} end>
                            <i className={item.icon}></i>
                            <span>{item.label}</span>
                        </NavLink>
                    </div>
                ))
            }
        </div>
    </div>
  )
}
