import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface PostState extends GeneralStateInterface{
    posts:  any[],
    post:  any,
}
const initialState: PostState = {
    posts: [],
    post: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}

export const getPosts = createAsyncThunk<any, Object>(
    'post/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getPost = createAsyncThunk<any, Object>(
    'post/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getMyPosts = createAsyncThunk<any, Object>(
    'post/my-list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/my-list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const setPost = createAsyncThunk<any, Object>(
    'post/create',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/create`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updatePost = createAsyncThunk<any, Object>(
    'post/update',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/update`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const deletePost = createAsyncThunk<any, Object>(
    'post/delete',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/delete`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const likePost = createAsyncThunk<any, Object>(
    'post/like',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/like`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unlikePost = createAsyncThunk<any, Object>(
    'post/unlike',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/unlike`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const cartPost = createAsyncThunk<any, Object>(
    'post/cart',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/cart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const uncartPost = createAsyncThunk<any, Object>(
    'post/uncart',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/uncart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const signalerPost = createAsyncThunk<any, Object>(
    'post/signaler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/signaler`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const commentPost = createAsyncThunk<any, Object>(
    'post/comment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/comment`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const uncommentPost = createAsyncThunk<any, Object>(
    'post/uncomment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}post/delete-comment`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false


            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false


            state.isCreateError = false
            state.isCreateSuccess = false
            state.isCreateLoading = false

            state.isDeleteError = false
            state.isDeleteSuccess = false
            state.isDeleteLoading = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getPosts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getPosts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.posts = action.payload
        })
        .addCase(getPosts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

       

            .addCase(getMyPosts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMyPosts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.posts = action.payload
            })
            .addCase(getMyPosts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getPost.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPost.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.post = action.payload
            })
            .addCase(getPost.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(setPost.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setPost.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.post = action.payload
            })
            .addCase(setPost.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(likePost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(likePost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(likePost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unlikePost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unlikePost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(unlikePost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(cartPost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(cartPost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(cartPost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncartPost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncartPost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(uncartPost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(commentPost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(commentPost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(commentPost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncommentPost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncommentPost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(uncommentPost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(signalerPost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(signalerPost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(signalerPost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updatePost.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updatePost.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.post = action.payload
            })
            .addCase(updatePost.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deletePost.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.posts = state.posts.filter((category: any) => category?._id !== action.payload?._id)
            })
            .addCase(deletePost.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

    }
})

export const { reset } = postSlice.actions
export default postSlice.reducer