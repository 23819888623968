import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../../routes';
import { recoverPassword } from '../../../../../redux/feature/auth/auth.slice';
import { AlertModule, Button, GridLayout, Textfield } from '../../../../components';
import { AuthLayout } from '../../../../layout';

export function RecoverPage () {
  const {username, token} = useParams()
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<any>({
    password: "", 
    confirmPassword: "", 
  })
  let navigate = useNavigate()
  const { token: tokens } = useContext(UserAuthContext);

  const [error, setError] = useState({
    field: "",
    message: ""
})

const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.password){
        setError({
            field: "password",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.confirmPassword){
        setError({
            field: "confirmPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError({
        field: "",
        message: ""
    })

    dispatch(recoverPassword({...data, username, token}))
}

  useEffect(() => {
      if(tokens){
          navigate(links.home)
      }
  }, [tokens, navigate]);
  
  useEffect(() => {
    if(!token ){
      navigate(links.login)
    }else{
      if(!username ){
        navigate(links.login)
      }
    }
  }, [token, username, navigate])

  return (
    <AuthLayout title={"Reinitialisation du mot de passe"} subtitle={""}>
      <form onSubmit={handleSubmit} className={"kubini-auth-form"}>
        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
            {
                isEditError ? (
                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
            }
            {
                isEditSuccess ? (
                    <AlertModule status={"st-success"} title={"Action réussie!!!"} message={"Votre mot de passe a été réinitialisé avec succès. Vous pouvez vous connecter maintenant"}/>
                ) : null
            }
            <div className={"kubini-auth-form__container"}>
                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--6"}>
                    
                    <div className={"kubini-auth-form__field"}>
                        <Textfield
                            id={"password"}
                            value={data.password}
                            require={true}
                            changeValue={(val: string) => setData({...data, password: val})}
                            type={"password"}
                            label={"Mot de passe"}
                            placeholder={"Veuillez insérer votre mot de passe"}
                            error={error}
                        />
                    </div>
                    <div className={"kubini-auth-form__field"}>
                        <Textfield
                            id={"confirmPassword"}
                            value={data.confirmPassword}
                            require={true}
                            changeValue={(val: string) => setData({...data, confirmPassword: val})}
                            type={"password"}
                            label={"Confirmer le mot de passe"}
                            placeholder={"Veuillez confirmer votre mot de passe"}
                            error={error}
                        />
                    </div>
                </GridLayout>
            </div>
            <div className={"kubini-auth-form__action"}>
                <Button btnType={"submit"} label={isEditLoading ? "Reinitialisation en cours..." : "Reinitialiser son mot de passe"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass={"icon-spin"}/>
            </div>
            <div className={"kubini-auth-form__info"}>
                <p><Link to={links.login} className='kubini-link'>Se connecter</Link></p>
            </div>
        </GridLayout>
      </form>
    </AuthLayout>
  )
}
