import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {AuthLayout, LoginWithGoogle} from "../../../../layout";
import {Button, GridLayout, ModalLayout, Textfield} from "../../../../components";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {UserAuthContext} from "../../../../context/AuthContext";
import {links} from "../../../../routes";
import {login, sendRecoverMail} from "../../../../../redux/feature/auth/auth.slice";
import {AlertModule} from "../../../../components/src/Alert";
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';

const PasswordForgotten = ({closeFnc}:{closeFnc: (val: boolean) => void}) => {

  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.auth);
  const [closeM, setCloseM] = useState<boolean>(false)
  const [data, setData] = useState<any>({
    email: "",
  })

  const [error, setError] = useState({
    field: "",
    message: ""
  })
  const handleSubmit = () => {

    if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    dispatch(sendRecoverMail(data))
    setCloseM(true)
  }


  const closeModal = () => {
    setData(null)
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isEditLoading} fnc={() => closeModal()}>
        <div className={"kubini-modal-form"}>
            <div className={"kubini-modal-form__header"}>
                <h2>Mot de passe oublié?</h2>
                <p>Veuillez entrer votre email, et nous vous enverrons un mail contenant les instructions pour reinitialiser votre mot de passe</p>
            </div>
            {
                isEditSuccess && closeM ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Un mail de reinitialisation de votre mot de passe vous a été envoyé</h2>
                                <p>Nous venons de vous envoyer un mail contenant les instructions pour reinitialiser votre mot de passe</p>
                                <GridLayout direction={"fd--row"} justify={"jc--center"} align={"al--center"} gap="g--6">
                                    <div className={"kubini-modal-form-status__action"}>
                                        <Button
                                            btnType={"button"}
                                            label={"Fermer..."}
                                            btnStyle={"is--neutral"}
                                            active={true}
                                            withIconLeft={false}
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form__container"}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isEditError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"email"}
                                            value={data.email}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, email: value})}
                                            type={"email"}
                                            label={"Email"}
                                            placeholder={"ex: john@doe.com"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"button"} onClick={() => handleSubmit()} label={isEditLoading ? "Envoi en cours..." : "Envoyer"} btnStyle={"is--primary"}active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </div>
                )
            }
        </div>
    </ModalLayout>
  )
}

export const LoginPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const url = searchParams.get('url')
    const [data, setData] = useState<any>({
        login: "",
        password: "",
    })
    const {state} = useLocation()
    const [passwordModal, setPasswordModal] = useState<boolean>(false)
    let navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { isLoginLoading, isLoginSuccess, isLoginError, message } = useAppSelector((state) => state.auth);
    const { token, setRedirectTo, } = useContext(UserAuthContext);

    useEffect(() => {
        if(token){
            navigate(url || links.home)
        }
    }, [token, navigate, url]);

    useEffect(() => {
        setRedirectTo(url || links.home)
    }, [setRedirectTo, url]);

    const [error, setError] = useState({
        field: "",
        message: ""
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.login){
            setError({
                field: "login",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.password){
            setError({
                field: "password",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError({
            field: "",
            message: ""
        })
        dispatch(login(data))
    }

    useEffect(() => {
        if(isLoginSuccess){
            window.location.reload()
        }
    }, [isLoginSuccess]);

    return (
        <AuthLayout title={"Connexion"} subtitle={"Heureux de vous revoir"}>
            <form onSubmit={handleSubmit} className={"kubini-auth-form"}>
                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                    {
                        isLoginError ? (
                            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                        ) : null
                    }

                    {
                        state?.status === "success" ? (
                            <AlertModule status={`st-success`} title={"Inscription"} message={state?.message}/>
                        ) : null
                    }
                    <div className={"kubini-auth-form__container"}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--6"}>
                            <div className={"kubini-auth-form__field"}>
                                <Textfield
                                    id={"login"}
                                    value={data.login}
                                    require={true}
                                    changeValue={(val: string) => setData({...data, login: val})}
                                    type={"text"}
                                    label={"Nom d'utilisateur ou Email"}
                                    error={error}
                                    placeholder={"Veuillez insérer votre nom d'utilisateur ou Email"}
                                />
                            </div>
                            <div className={"kubini-auth-form__field"}>
                                <Textfield
                                    id={"password"}
                                    value={data.password}
                                    require={true}
                                    changeValue={(val: string) => setData({...data, password: val})}
                                    type={"password"}
                                    label={"Mot de passe"}
                                    placeholder={"Veuillez insérer votre mot de passe"}
                                    error={error}
                                />
                            </div>
                        </GridLayout>
                    </div>
                    <div className={"kubini-auth-form__info"}>
                        <p><button type='button' className='kubini-link' onClick={() => setPasswordModal(true)}>Mot de passe oublié?</button></p>
                    </div>
                    <div className={"kubini-auth-form__action"}>
                        <Button btnType={"submit"} label={isLoginLoading ? "Connexion en cours..." : "Connexion"} btnStyle={"is--primary"} active={!isLoginLoading} withIconLeft={isLoginLoading} iconLeftClass={"icon-spin"}/>
                    </div>
                    <div className='kubini-auth-form__info'><p>ou</p></div>
                    <div className={"kubini-auth-form__action"}><LoginWithGoogle/></div>

                    <div className={"kubini-auth-form__info"}>
                        <p>Je n'ai pas de compte? <Link to={links.register} className='kubini-link'>Créer un compte</Link></p>
                    </div>
                </GridLayout>
                {
                    passwordModal ? (
                        <PasswordForgotten closeFnc={setPasswordModal}/>
                    ) : null
                }
            </form>
        </AuthLayout>
    );
};