import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getPubs, reset } from '../../../../../redux/feature/pub/pub.slice';
import { Button } from '../../../../components';
import { capitalize, formatMyDate, formatNumber, formatNumber2, truncate } from '../../../../../utils/function';
import { addDays, format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

const AnnoncesContain = ({articles}:{articles: any[]}) => {
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()

  return (
    <>
      {
        articles ? (
          <>
            {
              articles.filter((el: any) => el.supprime === false && el.adminAction === false).length > 0 ? (
                <div className='enka-home-account-grid'>
                  {
                    articles.filter((el: any) => el.supprime === false && el.adminAction === false).slice(0, limit).map((item: any, index: number) => (
                      <div className='enka-home-account-grid-item' key={index}>
                        <button className='enka-card' type='button' onClick={() => navigate(links.article + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                            <div className='enka-card-image'>
                              {
                                item?.images?.length > 0 ? (
                                  item?.images[0]?.url ? (
                                    <img src={item?.images[0]?.url} alt="cover" />
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                ) : (
                                  <i className="icon-picture-flower"></i>
                                )
                              }
                            </div>
                            <div className='enka-card-info'>
                              <div className='enka-card-info-id'>
                                <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                              </div>

                              <div className='enka-card-info-stats'>
                                <div className='enka-card-info-user'>
                                  <div className='enka-card-info-user__avatar'>
                                  {
                                    item?.user?.auth?.avatar ? (
                                      <img src={item?.user?.auth?.avatar?.url} alt="" />
                                    ) : (
                                      <i className="icon-user-single"></i>
                                    )
                                  }
                                  </div>
                                  <div className='enka-card-info-user__info'>
                                    <strong>{item?.user?.auth?.fullname}</strong>
                                    <span>{item?.user?.city?.name || ""}</span>
                                  </div>
                                </div>
                                <div className='enka-card-info-stat'>
                                  <div className='enka-card-info-stat__item'>
                                    <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                  </div>
                                  <div className='enka-card-info-stat__item'>
                                    <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                  </div>
                                </div>
                                <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                              </div>
                            </div>
                          </button>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <strong>Aucun element enregistré...</strong>
              )
            }
            {
              articles.filter((el: any) => el.supprime === false && el.adminAction === false).length > 12 ? (
                <div className='enka-home-account-action'>
                  <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                </div>
              ) : null
            }
          </>
        ) : null
      }
    </>
  )
}
const EvensContain = ({events}:{events: any[]}) => {
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()
  return (
    <>
      {
        events ? (
            <>
              {
                events.filter((el: any) => el.supprime === false && el.adminAction === false).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 0 ? (
                  <div className='enka-home-account-grid'>
                    {
                      events.filter((el: any) => el.supprime === false && el.adminAction === false).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).slice(0, limit).map((item: any, index: number) => (
                        <div className='enka-home-account-grid-item' key={index}>
                          <button className='enka-card' type='button' onClick={() => navigate(links.event + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.date_event ? capitalize(format(new Date(item?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</h2>
                                  
                                  <span>{"Lieu :" + item?.lieu?.toUpperCase()}</span> 
                                </div>

                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-user'>
                                    <div className='enka-card-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-card-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                          </button>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <strong>Aucun element enregistré...</strong>
                )
              }
              {
                events.filter((el: any) => el.supprime === false && el.adminAction === false).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 12 ? (
                  <div className='enka-home-account-action'>
                    <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                  </div>
                ) : null
              }
            </>
          ) : null
      }
    </>
  )
}

const PostContain = ({posts}:{posts: any[]}) => {
  const [limit, setLimit] = useState<number>(12)
  let navigate = useNavigate()
  return (
    <>
      {
        posts ? (
          <>
            {
              posts.filter((el: any) => el.supprime === false && el.adminAction === false).length > 0 ? (
                <div className='enka-home-account-grid'>
                  {
                    posts.filter((el: any) => el.supprime === false && el.adminAction === false).slice(0, limit).map((item: any, index: number) => (
                      <div className='enka-home-account-grid-item type2' key={index}>
                        
                        <button className='enka-post' type='button' onClick={() => navigate(links.post + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-post__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                              <div className='enka-post-info'>
                                <div className='enka-post-info-id'>
                                  <h2>{item?.titre ? truncate(item?.titre, 80) : "ND"}</h2>
                                  <p>{item?.description?.text  ? truncate(item?.description?.text, 80) : "ND"} </p>
                                </div>

                                <div className='enka-post-info-stats'>
                                  <div className='enka-post-info-user'>
                                    <div className='enka-post-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-post-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-post-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-post-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                          </button>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <strong>Aucun element enregistré...</strong>
              )
            }
            {
              posts.filter((el: any) => el.supprime === false && el.adminAction === false).length > 12 ? (
                <div className='enka-home-account-action'>
                  <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                </div>
              ) : null
            }
          </>
        ) : null
      }
    </>
  )
}

export const CartPage = () => {
  const dispatch = useAppDispatch();
  const { info, token } = useContext(UserAuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get('tabs')
  let navigate = useNavigate()
  const {isLoading: isPLoading, isError: isPError, isSuccess: isPSuccess, pubs, message: pMessage} = useAppSelector((state) => state.pub);
  
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.cart}`)
    }
  }, [token, navigate]);

  useEffect(() => {
    dispatch(getPubs({}))

    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  if(isPLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }
  if(isPError){
    return (
      <div className={"kubini-user-error"}>
        <div className={"kubini-user-error__container"}>
          <i className="icon-warning-triangle"/>
          <h2>Oupss!! Une erreur est survenue</h2>
          <p>{pMessage}</p>
          <div className={"kubini-user-error__action"}>
            <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <MainLayout title='Enrégistrer pour plus tard'>
      <div className='enka-home'>
          {
            isPSuccess && pubs ? (
              pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                <div  className='enka-ads is--top' >
                  
                  <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>

                  <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                  <span>publicité</span>
                  </a>
                </div>
              ) : null
            ) : null
          }
          <div className='enka-home__header'>
            <h1>Enregistrer pour plus tard</h1>
          </div>
          <br />
          <div className='enka-home-account-navigation'>
            <div className='enka-home-account-navigation__content'>
              <button type='button' className={`enka-home-account-navigation__item ${tabs ? (tabs === "annonce" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.cart+"?tabs=annonce")}>
                <span>Annonces</span>
              </button>
              <button type='button' className={`enka-home-account-navigation__item ${tabs === "evenement" ? "is--active" : ""}`} onClick={() => navigate(links.cart+"?tabs=evenement")}>
                <span>Evèmenents</span>
              </button>
              <button type='button' className={`enka-home-account-navigation__item ${tabs === "publication" ? "is--active" : ""}`} onClick={() => navigate(links.cart+"?tabs=publication")}>
                <span>Publications</span>
              </button>
            </div>
          </div>

          <div className={`enka-home-account-navigation__container ${tabs ? (tabs === "annonce" ? "is--active" : "") : "is--active"}`}>
            {info ?<AnnoncesContain articles={info?.user?.articles}/> : null} 
          </div>
          <div className={`enka-home-account-navigation__container ${tabs === "evenement" ? "is--active" : ""}`}>
            {info ?<EvensContain events={info?.user?.events}/> : null} 
          </div>
          <div className={`enka-home-account-navigation__container ${tabs === "publication" ? "is--active" : ""}`}>
            {info ? <PostContain posts={info?.user?.posts}/> : null} 
          </div>
      </div>
    </MainLayout>
  )
}
