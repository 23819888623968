import React from 'react';
import ReactDOM from 'react-dom/client';
import "./app/assets/style/index.scss"
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from './app/context/ThemeContext';
import { GoogleOAuthProvider } from "@react-oauth/google"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={'474733627025-c4qvggp7l0oun2ja3ucclrcckrr6oh30.apps.googleusercontent.com'}>
      <ThemeProvider>
        <Provider store={store}>
            <App />
        </Provider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

