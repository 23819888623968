import React, { useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getArticles, reset } from '../../../../../redux/feature/annonce/annonce.slice';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';
import { Button } from '../../../../components';
import { formatMyDate, formatNumber, formatNumber2, truncate } from '../../../../../utils/function';
import { links } from '../../../../routes';

export const AnnoncesPage = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get('type')
  const {isLoading, isError, isSuccess, articles, message} = useAppSelector((state) => state.annonce);
  const {isLoading: isPLoading, isError: isPError, isSuccess: isPSuccess, pubs, message: pMessage} = useAppSelector((state) => state.pub);
  const [limit, setLimit] = useState<number>(32)

  useEffect(() => {
    dispatch(getArticles({}))
    dispatch(getPubs({}))

    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch])

  if(isLoading || isPLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }
  if(isError || isPError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message || pMessage}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
        </div>
    )
  }
  
  return (
    <MainLayout title='Annonces'>
      <div className='enka-home'>
          {
            isPSuccess && pubs ? (
              pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                <div  className='enka-ads is--top' >
                  
                  <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>

                  <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                  <span>publicité</span>
                  </a>
                </div>
              ) : null
            ) : null
          }
          <div className='enka-home__header'>
            <h1>Annonces</h1>
          </div>
          <br />
          {
            isSuccess && articles ? (
              <>
              {
                articles?.filter((el: any) => tabs === "epingle" ? el.epingle : true)?.length > 0 ? (
                  <div className='enka-home-grid-grid'>
                    {
                      articles?.filter((el: any) => tabs === "epingle" ? el.epingle : true)?.slice(0, limit).map((item: any, index: number) => (
                        <div className='enka-home-grid-item' key={index}>
                          <button className='enka-card' type='button' onClick={() => navigate(links.article + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                                <div className='enka-card-image'>
                                  {
                                    item?.images?.length > 0 ? (
                                      item?.images[0]?.url ? (
                                        <img src={item?.images[0]?.url} alt="cover" />
                                      ) : (
                                        <i className="icon-picture-flower"></i>
                                      )
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  }
                                </div>
                                <div className='enka-card-info'>
                                  <div className='enka-card-info-id'>
                                    <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                    <h2>{item?.price ? formatNumber2(item.price)+" XOF" : "ND"}</h2>
                                    <span>{item?.subcategory?.name + " - " + item?.subcategory?.category?.name?.toUpperCase()}</span>
                                  </div>

                                  <div className='enka-card-info-stats'>
                                    <div className='enka-card-info-user'>
                                      <div className='enka-card-info-user__avatar'>
                                      {
                                        item?.user?.auth?.avatar ? (
                                          <img src={item?.user?.auth?.avatar?.url} alt="" />
                                        ) : (
                                          <i className="icon-user-single"></i>
                                        )
                                      }
                                      </div>
                                      <div className='enka-card-info-user__info'>
                                        <strong>{item?.user?.auth?.fullname}</strong>
                                        <span>{item?.user?.city?.name || ""}</span>
                                      </div>
                                    </div>
                                    <div className='enka-card-info-stat'>
                                      <div className='enka-card-info-stat__item'>
                                        <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                      </div>
                                      <div className='enka-card-info-stat__item'>
                                        <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                      </div>
                                    </div>
                                    <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                  </div>
                                </div>
                              </button>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <strong>Aucun element trouvé</strong>
                )
              }
              {
                articles?.filter((el: any) => tabs === "epingle" ? el.epingle : true)?.length > 32 ? (
                  <div className='enka-home-account-action'>
                    <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                  </div>
                ) : null
              }
              
              </>
            ) : null
          }
        </div>
    </MainLayout>
  )
}
