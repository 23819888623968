import React, {createContext, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {getMe, logout} from "../../redux/feature/auth/auth.slice";


export const UserAuthContext = createContext<null | any>(null);

interface AuthContextParams {
    children?: React.ReactNode
}


export const AuthContext: React.FC<AuthContextParams> = ({children}) => {
    const [info, setInfo] = useState<any | null>(null)
    const [redirectTo, setRedirectTo] = useState<any | null>(null)
    const dispatch = useAppDispatch();
    const {token, me, isLoading, isError, message} = useAppSelector((state) => state.auth);


    useEffect(() => {
        if(token){
            // @ts-ignore
            dispatch(getMe())
        }

    }, [dispatch, token])

    useEffect(() => {
        setInfo(me)
    }, [me])



    const logOut = () => {
        // @ts-ignore
        dispatch(logout())
        window.location.reload()
    }

    useEffect(() => {
        if(isError){
            // @ts-ignore
            dispatch(logout())
        }
    }, [dispatch, isError]);

    return (
        <UserAuthContext.Provider value={{
            info,
            token,
            setInfo,
            logOut,
            redirectTo, 
            setRedirectTo,
            isLoading,
            isError,
            message
        }}>
            {children}
        </UserAuthContext.Provider>
    );
};
