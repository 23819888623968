import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../../routes';
import { getCitiesOption, register, reset } from '../../../../../redux/feature/auth/auth.slice';
import { AuthLayout } from '../../../../layout';
import { AlertModule, Button, GridLayout, Selectfield, Textfield } from '../../../../components';

export function RegisterPage () {
  const [data, setData] = useState<any>({
    fullname: "", 
    username: "", 
    email: "", 
    password: "", 
    confirmPassword: "", 
    cityId: "", 
    telephone: ""
  })
  let navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message, cities, isLoading, isError, isSuccess } = useAppSelector((state) => state.auth);
  const { token } = useContext(UserAuthContext);

  useEffect(() => {
      if(token){
          navigate(links.home)
      }
  }, [token, navigate]);

  const [error, setError] = useState({
      field: "",
      message: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.fullname){
          setError({
              field: "fullname",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.username){
          setError({
              field: "username",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.telephone){
          setError({
              field: "telephone",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.email){
          setError({
              field: "email",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.cityId){
          setError({
              field: "cityId",
              message: "Veuillez renseigner ce champs"
          })
          return
      }

      if(!data.password){
          setError({
              field: "password",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.confirmPassword){
          setError({
              field: "confirmPassword",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      setError({
          field: "",
          message: ""
      })
      dispatch(register(data))
  }

  useEffect(() => {
    dispatch(getCitiesOption({}))

    return () => {
        dispatch(reset())
    }
  }, [dispatch])


  useEffect(() => {
    if(isCreateSuccess){
        navigate(links.login, {
            state: {
                message: "Votre compte a été créé avec succès, veuillez vous pouvez vous maintenant vous reconnecter",
                status: "success"
            }
        })
    }
  }, [navigate, isCreateSuccess])
  
  if(isLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }

  if(isError){
    return (
        <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>{message}</h2>
          </div>
        </div>
    )
  }


  return (
    <>
      {
        isSuccess && cities ? (
          <AuthLayout title={"Inscription"} subtitle={"Bienvenue sur Enka-market"}>
            <form onSubmit={handleSubmit} className={"kubini-auth-form"}>
              <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                  {
                      isCreateError ? (
                          <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                      ) : null
                  }
                  {
                      isCreateSuccess ? (
                          <AlertModule status={"st-success"} title={"Action réussie!!!"} message={"Votre compte a été créé avec succès, veuillez vous pouvez vous maintenant vous reconnecter"}/>
                      ) : null
                  }
                  <div className={"kubini-auth-form__info"}>
                    <p>Tous les champs sont obligatoires sauf ceux marqués facultatifs (optionels)  </p>
                  </div>
                  <div className={"kubini-auth-form__container"}>
                      <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--6"}>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"fullname"}
                                  value={data.fullname}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, fullname: val})}
                                  type={"text"}
                                  label={"Nom complet"}
                                  error={error}
                                  placeholder={"Veuillez insérer votre nom et prénom(s)"}
                              />
                          </div>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"username"}
                                  value={data.username}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, username: val})}
                                  type={"username"}
                                  label={"Nom d'utilisateur ou pseudonyme"}
                                  error={error}
                                  placeholder={"Veuillez insérer votre nom d'utilisateur. eg: jonhdoe77"}
                              />
                          </div>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"email"}
                                  value={data.email}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, email: val})}
                                  type={"email"}
                                  label={"Email"}
                                  error={error}
                                  placeholder={"Veuillez insérer votre email"}
                              />
                          </div>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"telephone"}
                                  value={data.telephone}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, telephone: val})}
                                  type={"phone"}
                                  label={"Telephone"}
                                  error={error}
                                  placeholder={"Veuillez insérer votre telephone"}
                              />
                          </div>
                          <div className={"kubini-auth-form__field"}>
                            <Selectfield
                                  id={"cityId"}
                                  value={data.cityId}
                                  require={true}
                                  changeValue={(value: any) => setData({...data, cityId: value})}
                                  tabs={cities}
                                  label={"Ville de residence"}
                                  placeholder={"ex: Bobo"}
                                  error={error}
                              />
                            </div>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"password"}
                                  value={data.password}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, password: val})}
                                  type={"password"}
                                  label={"Mot de passe"}
                                  placeholder={"Veuillez insérer votre mot de passe"}
                                  error={error}
                              />
                          </div>
                          <div className={"kubini-auth-form__field"}>
                              <Textfield
                                  id={"confirmPassword"}
                                  value={data.confirmPassword}
                                  require={true}
                                  changeValue={(val: string) => setData({...data, confirmPassword: val})}
                                  type={"password"}
                                  label={"Confirmer le mot de passe"}
                                  placeholder={"Veuillez confirmer votre mot de passe"}
                                  error={error}
                              />
                          </div>
                      </GridLayout>
                  </div>
                  <div className={"kubini-auth-form__info"}>
                    <p>En continant l'inscription vous acceptez nos différentes <Link to={links.terms} className='kubini-link'>conditions d'utilisation</Link> </p>
                  </div>
                  <div className={"kubini-auth-form__action"}>
                      <Button btnType={"submit"} label={isCreateLoading ? "Inscription en cours..." : "Créer son compte"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={isCreateLoading} iconLeftClass={"icon-spin"}/>
                  </div>
                  <div className={"kubini-auth-form__info"}>
                      <p>J'ai déjà un compte? <Link to={links.login} className='kubini-link'>Se connecter</Link></p>
                  </div>
              </GridLayout>
            </form>
          </AuthLayout>
        ) : null
      }
    </>
    
  )
}
