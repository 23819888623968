import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertModule, Button, ModalLayout, Textfield } from '../../../../components';
import { getPost, reset, updatePost } from '../../../../../redux/feature/post/post.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';

const EditContain = ({item}:{item: any}) => {
  const {isEditLoading, isEditError, isEditSuccess, message} = useAppSelector((state) => state.post);
  const [data, setData] = useState<any>({
    titre: item.titre, 
    description: item.description.text, 
    id: item?._id
  });
  let navigate = useNavigate()
  const dispatch = useAppDispatch();

  const [openC, setOpenC] = useState<boolean>(false);
  const [error, setError] = useState<{ type: string, message: string }>({
    type: "",
    message: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.titre){
        setError({
            type: "title",
            message: "Veuillez renseigner le titre"
        })
        return
    }
    if(!data.description){
        setError({
            type: "description",
            message: "Veuillez renseigner la description"
        })
        return
    }

    setOpenC(true)
    dispatch(updatePost(data))
  }

  useEffect(() => {
    if(isEditSuccess){
      setOpenC(true)
    }
  }, [isEditSuccess])

  return (
    <>
      <form className='enka-home-create__container' onSubmit={handleSubmit}>
        {
          isEditError ? (
            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
          ) : null
        }
        <div className='enka-home-create__fields'>
          <div className='enka-home-create__field'>
            <Textfield
                id={"titre"}
                value={data.titre}
                require={true}
                changeValue={(val: string) => setData({...data, titre: val})}
                type={"text"}
                label={"Titre de l'annonce"}
                error={error}
                placeholder={"Veuillez insérer le titre"}
            />
          </div>
          <div className='enka-home-create__field'>
            <Textfield
                id={"description"}
                value={data.description}
                require={true}
                changeValue={(val: string) => setData({...data, description: val})}
                type={"textarea"}
                label={"Description de l'annonce"}
                error={error}
                placeholder={"Veuillez insérer la description"}
            />
          </div>
        </div>
        <div className='enka-home-create__action'>
          <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass={"icon-spin"}/>
        </div>
      </form>

      <ModalLayout size={"is--md"} id={"add"} fnc={() => {}} withClose={false} isOpen={openC}>
          <div className={"kubini-modal-form-status"}>
            <div className={"kubini-modal-form-status__container"}>
                <div className={"kubini-modal-form-status__icon is--success"}>
                    <i className="icon-validation-check"/>
                </div>
                <div className={"kubini-modal-form-status__text"}>
                    <h2>Modification d'une annonce</h2>
                    <p>Les modifications ont bien été enregistrées</p>
                    <div className={"kubini-modal-form-status__action"}>
                        <Button
                            btnType={"button"}
                            label={"Revenir dans mon compte..."}
                            btnStyle={"is--neutral"}
                            active={true}
                            withIconLeft={false}
                            onClick={() => {
                              navigate(links.account+"?tabs=publication")
                            }}
                        />
                    </div>
                </div>
            </div>
          </div>
      </ModalLayout>
    </>
  )
}

export const PostEditPage = () => {
  const {slug} = useParams()
  const { token } = useContext(UserAuthContext);
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  const {isLoading: isALoading, isSuccess: isASuccess, isError: isAError, post, message: aMessage} = useAppSelector((state) => state.post);
  const dispatch = useAppDispatch();
  let navigate = useNavigate()

  useEffect(() => {
    if(slug){
      dispatch(getPost(slug))
      dispatch(getPubs({}))
    }

    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch, slug])


  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.edit_post + slug}`)
    }
  }, [token, navigate, slug]);

  if(isLoading || isALoading){
    return (
      <div className={"kubini-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError || isAError){
    return (
      <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message || aMessage}</p>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }
  
  return (
    <MainLayout title="Modification d'une publication">
      <div className='enka-home'>
        <div className='enka-home__header'>
          <h1>Modification d'une publication</h1>
        </div>
        <div className='enka-home__create enka-home-create'>
          {isASuccess && post ? <EditContain item={post}/> : null}
          <div className='enka-home-create__side'>
            {
              isSuccess && pubs ? (
                pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                  <div  className='enka-ads is--side' >
                    <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                    <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                    <span>publicité</span>
                    </a>
                  </div>
                ) : null
              ) : null
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
