import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { cartPost, commentPost, getPost, likePost, reset, uncartPost, uncommentPost, unlikePost } from '../../../../../redux/feature/post/post.slice';
import { getPubs, reset as preset } from '../../../../../redux/feature/pub/pub.slice';
import { Button, Facebook, Instagram, ModalLayout, Telegram, Toast, Twitter, Whatsapp } from '../../../../components';
import { capitalize, formatMyDate, formatNumber, formatNumber2 } from '../../../../../utils/function';

export const PostPage = () => {
  const {slug} = useParams()
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()
  const [contactOpen, setContactOpen] = useState<boolean>(false)
  const [typeLike, setTypeLike] = useState<string>("")
  const [commentContent, setCommentContent] = useState<string>("")
  const [readMore, setReadMore] = useState<number>(12)
  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.event + slug}`)
    }
  }, [token, navigate, slug]);
  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, post,isEditError, isEditLoading, isEditSuccess, message} = useAppSelector((state) => state.post);
  const {isLoading: isPLoading, isError: isPError, isSuccess: isPsuccess, pubs, message: pMessage} = useAppSelector((state) => state.pub);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabs = searchParams.get('tabs')

  const [isNewNotif, setIsNewNotif] = useState<boolean>(false)
  
  useEffect(() => {
    if(slug){
      dispatch(getPost(slug))
      dispatch(getPubs({}))
    }


    return () => {
      dispatch(reset())
      dispatch(preset())
    }
  }, [dispatch, slug])

  useEffect(() => {
    if(isEditError && typeLike){
      setIsNewNotif(true)
    }
  }, [isEditError, typeLike])

  useEffect(() => {
    if(isEditSuccess && typeLike){
      setIsNewNotif(true)
      if(typeLike === "comment"){
        setCommentContent("")
      }
      if(typeLike === "cart" || typeLike === "uncart"){
        window?.location.reload()
      }
    }
  }, [isEditSuccess, typeLike])

  useEffect(() => {
    if(post && info){
      if(post?.user?.auth?._id === info?._id){
        navigate(links.my_post + slug)
      }
    }
  }, [post, info, slug, navigate])

  return (
    <MainLayout title='Publication'>
      <div className='enka-home'>
        {
          isLoading || isPLoading ? (
            <div className={"kubini-loader"}>
              <i className="icon-spin"/>
            </div>
          ) : (
            isError || isPError ? (
              <div className={"kubini-user-error"}>
                <div className={"kubini-user-error__container"}>
                  <i className="icon-warning-triangle"/>
                  <h2>Oupss!! Une erreur est survenue</h2>
                  <p>{message || pMessage}</p>
                  <div className={"kubini-user-error__action"}>
                    <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                  </div>
                </div>
              </div>
            ) : (
              isSuccess && isPsuccess && pubs && post ? (
                <>
                  <div className='enka-product-banner'>
                    <div className='enka-product-banner__images enka-product-banner-images'>
                      <div className='enka-product-banner-description'>
                        <strong>Description</strong>
                        <p>{post.description?.text}</p>
                      </div>
                    </div>
                    <div className='enka-product-banner__side'>
                      <div className='enka-product-banner__info enka-product-banner-info'>
                        <div className='enka-product-banner-info__details'>
                          <h2>{post.titre}</h2>
                         <div className='enka-product-banner-info-user'>
                            <div className='enka-product-banner-info-user__avatar'>
                            {
                              post?.user?.auth?.avatar ? (
                                <img src={post?.user?.auth?.avatar?.url} alt="" />
                              ) : (
                                <i className="icon-user-single"></i>
                              )
                            }
                            </div>
                            <div className='enka-product-banner-info-user__info'>
                              <strong>{post?.user?.auth?.fullname}</strong>
                              <span>{post?.user?.city?.name || ""}</span>
                            </div>
                          </div>
                          <div className='enka-product-banner-info__avis enka-product-banner-info-avis'>
                            <div className='enka-product-banner-info-avis__item'>
                              <i className="icon-favorite-heart"></i> <span>{post?.likes?.length > 0 ? formatNumber(post?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                            </div>
                            <div className='enka-product-banner-info-avis__item'>
                              <i className="icon-chat-two-bubbles-oval"></i> <span>{post?.comments?.length > 0 ? formatNumber(post?.comments?.length) : "Aucun"} commentaire(s)</span>
                            </div>
                          </div>
                        </div>
                        <div className='enka-product-banner-info__orders'>
                          
                          <div className='enka-product-banner-info__actions'>
                            <Button btnType={'button'} label={info?.user?.posts?.filter((el: any) => (el?._id === post?._id)).length > 0 ? "Retirer du panier" :'Enregistrer pour plus tard'} btnStyle={info?.user?.posts?.filter((el: any) => (el?._id === post?._id)).length > 0 ? 'is--neutral':'is--primary'} active={true} withIconLeft={true} iconLeftClass='icon-shopping-cart' onClick={() => {
                              setTypeLike("")
                              if(info?.user?.posts?.filter((el: any) => (el?._id === post?._id)).length > 0){
                                dispatch(uncartPost({articleId: post?._id}))
                                setTypeLike("uncart")
                              }else{
                                dispatch(cartPost({articleId: post?._id}))
                                setTypeLike("cart")
                              }
                            }}/>
                            <Button btnType={'button'} label={'Contacter le vendeur'} btnStyle={'is--secondary'} active={true} withIconLeft={true} iconLeftClass='icon-content-book-phone' onClick={() => setContactOpen(true)}/>
                          </div>
                        </div>
                      </div>
                      {
                        pubs?.filter((el: any) => el?.type_pub === "home-top")?.length > 0 ? (
                          <a className='enka-product-banner__ads' href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                              <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                              <span>publicité</span>
                          </a>
                        ) : null
                      }
                    </div>
                  </div>

                  <div className='enka-product-navigation'>
                    <div className='enka-product-navigation__nav enka-product-navigation-nav'>
                      <button type='button' className={`enka-product-navigation-nav__item ${tabs ? (tabs === "comments" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.post + slug + `?tabs=comments`)}>
                        <span>Commentaires ({post?.comments?.length > 0 ? formatNumber(post?.comments?.length) : "Aucun"} commentaire(s))</span>
                      </button>
                    </div>
                    <div className={`enka-product-navigation__container ${tabs ? (tabs === "comments" ? "is--active" : "") : "is--active"}`}>
                      <div className='enka-product-form'>
                        <div className='enka-product-form__likes enka-product-form-likes'>
                          <button type='button' className={`enka-product-form-likes__btn ${isEditLoading && (typeLike === "like" || typeLike === "unlike") ? "is--disable" : post?.likes?.filter((el:any) => el?.auth?._id === info?._id).length > 0 ? "is--active" : ""}`} onClick={() => {
                            if(post?.likes?.filter((el:any) => el?.auth?._id === info?._id).length > 0){
                              setTypeLike("")
                              dispatch(unlikePost({articleId: post?._id}))
                              setTypeLike("unlike")
                            }else{
                              setTypeLike("")
                              dispatch(likePost({articleId: post?._id}))
                              setTypeLike("like")
                            }
                          }}>
                            <i className={isEditLoading && (typeLike === "like" || typeLike === "unlike") ? "icon-spin" : "icon-favorite-heart"}></i>
                            <span>{post?.likes ? formatNumber2(post?.likes.length) : "ND"}</span>
                          </button>
                        </div>
                        <div className='enka-product-form__comments enka-product-form-comments'>
                          <div className='enka-product-form-comments__field'>
                            <textarea  placeholder='Donner votre avis' value={commentContent} onChange={(e) => setCommentContent(e.target.value)}/>
                          </div>
                          <div>
                            <button className='enka-product-form-comments__btn' type='button' onClick={() => {
                              if(commentContent){
                                setTypeLike("")
                                dispatch(commentPost({articleId: post?._id, content: commentContent}))
                                setTypeLike("comment")
                              }
                            }}>
                              <i className={isEditLoading && typeLike === "comment" ? "icon-spin" :"icon-chat-two-bubbles-oval"}></i>
                              <span>Envoyer</span>
                            </button>

                          </div>
                        </div>
                      </div>
                      <div className='enka-product-comments'>
                        <span>Les commentaires</span>
                          {
                            post?.comments?.filter((el: any) => el.supprime === false).sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, readMore).map((item: any, index: number) => (
                              <div className='enka-product-comment' key={index}>
                                <div className='enka-product-comment__user'>
                                  {
                                    item?.user?.auth?.avatar ? (
                                      <img src={item?.user?.auth?.avatar?.url} alt="" />
                                    ) : (
                                      <i className="icon-user-single"></i>
                                    )
                                  }
                                </div>
                                <div className='enka-product-comment__container'>
                                  <strong>{item?.user?.auth?.fullname}</strong>
                                  <p>{item.content}</p>
                                  <span>{capitalize(formatMyDate(item?.createdAt))}</span>
                                  {
                                    info?._id === item?.user?.auth?._id ? (
                                      <button type='button' onClick={() => {
                                        setTypeLike("")
                                        dispatch(uncommentPost({articleId: post?._id, commentId: item?._id}))
                                        setTypeLike("uncomment")
                                      }}>
                                        Supprimer
                                      </button>
                                    ) : null
                                  }
                                </div>
                                
                              </div>
                            ))
                          }
                          {
                             post?.comments?.filter((el: any) => el.supprime === false)?.length > 12 ? (
                              <button className='enka-home-products__more enka-home-products-more' type='button' onClick={() => setReadMore(readMore + 12)}>
                                <div className='enka-home-products-more-line'></div>
                                  <span>Afficher plus</span>
                                <div className='enka-home-products-more-line'></div>
                              </button>
                             ) : null
                          }
                      </div>
                    </div>
                  </div>

                  <ModalLayout size={"is--md"} id={"contact"} fnc={() => setContactOpen(false)} withClose={true} isOpen={contactOpen}>
                    <div className='enka-modal-share'>
                      <div className='enka-modal-share__header'>
                        <h2>Réseaux sociaux et Contacts</h2>
                      </div>
           
                      <div className='enka-modal-share__socials enka-modal-share-socials'>
                          {
                            post?.user?.social?.facebook ? (
                              <a href={post?.user?.social?.facebook} target='_blank' rel="noreferrer" className='enka-modal-share-social facebook'>
                                <Facebook/>
                              </a>
                            ) : null
                          }
                          {
                            post?.user?.social?.facebook ? (
                              <a href={post?.user?.social?.twitter} target='_blank' rel="noreferrer" className='enka-modal-share-social twitter'>
                                <Twitter/>
                              </a>
                            ) : null
                          }
                          {
                            post?.user?.social?.telegram ? (
                              <a href={post?.user?.social?.telegram} target='_blank' rel="noreferrer" className='enka-modal-share-social telegram'>
                                <Telegram/>
                              </a>
                            ) : null
                          }
                          {
                            post?.user?.social?.instagram ? (
                              <a href={post?.user?.social?.instagram} target='_blank' rel="noreferrer" className='enka-modal-share-social instagram'>
                                <Instagram/>
                              </a>
                            ) : null
                          }
                          {
                            post?.user?.social?.whatsapp ? (
                              <a href={`https://wa.me/${post?.user?.social?.whatsapp}`} target='_blank' rel="noreferrer" className='enka-modal-share-social whatsapp'>
                                <Whatsapp/>
                              </a>
                            ) : null
                          }
                          <a href={`mailto:${post?.user?.auth?.email}`} target='_blank' rel="noreferrer" className='enka-modal-share-social email'>
                            <i className="icon-mail-send-envelope"></i>
                          </a>
                          <a href={`tel:${post?.user?.auth?.telephone}`} target='_blank' rel="noreferrer" className='enka-modal-share-social phone'>
                            <i className="icon-telephone-ringing"></i>
                          </a>
                      </div>
                    </div>
                  </ModalLayout>
                  {
                    isNewNotif ? (
                      <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsNewNotif(false)}>
                        <p>{message || (typeLike === "like" ? "Vous avez aimé cette annonce" : typeLike === "unlike" ? "Vous n'aimez plus cette annonce" : typeLike === "comment" ?  "Votre avis a été envoyé" : typeLike === "cart" ? "Vous avez enregistré dans le panier" : typeLike === "uncart" ? "Vous avez retiré du panier" : "Cet avis a bien été supprimé")}</p>
                      </Toast>
                    ) : null
                  }
                </>
              ) : (
                <div className={"kubini-user-error"}>
                    <div className={"kubini-user-error__container"}>
                      <i className="icon-warning-triangle"/>
                      <h2>Element introuvable</h2>
                      <div className={"kubini-user-error__action"}>
                        <Button btnType={"button"} label={"Revenir vers l'accueil"} btnStyle={"is--primary"} active={true} onClick={() => navigate(links.home)}/>
                      </div>
                    </div>
                </div>
              )
            )
          )
        }
      </div>
    </MainLayout>
  )
}
