import React from 'react'
import { MainLayout } from '../../../layout'

export const AboutPage = () => {
  return (
    <MainLayout title="Foire aux questions">
      <div className='enka-home'>
        <div className='enka-home__header'>
          <h1>Foire aux questions</h1>
          <p style={{
            maxWidth: "60em"
          }}>Enka-market est la plus grande plateforme de marché en ligne au Burkina Faso. Trouvez les meilleures offres sur le site de référence pour les petites annonces entre particuliers et professionnels. Avec des millions d'annonces, découvrez de bonnes affaires dans des catégories variées. Publiez une annonce gratuite en toute simplicité pour vendre, rechercher, donner vos biens de seconde main, trouver un emploi ou promouvoir vos événements et services. </p>
        </div>
        <br />

        <div className='enka-home-rules'>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Qu'est-ce que notre site de petites annonces ?</h2>
            <div className='enka-home-rule__container'>
              <p>Notre site est une plateforme de recherche de travail, d’évènement et de   mise en relation entre professionnels et particuliers souhaitant acheter ou vendre des produits et services dans une multitude de catégories.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Comment créer un compte ?</h2>
            <div className='enka-home-rule__container'>
              <p>Pour créer un compte, cliquez sur "Inscription" en haut de la page d'accueil, remplissez les informations requises et validez votre adresse e-mail via le lien de confirmation envoyé ou faites-le directement avec l'email </p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Comment publier une annonce ?</h2>
            <div className='enka-home-rule__container'>
              <p>Connectez-vous à votre compte, cliquez sur "Publier une annonce", sélectionnez la catégorie appropriée, remplissez les détails du produit ou service, ajoutez des photos et publiez.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Y a-t-il des frais pour publier une annonce ?</h2>
            <div className='enka-home-rule__container'>
              <p>La création du compte et la publication d'une annonce est gratuite..</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Comment puis-je contacter un vendeur ?</h2>
            <div className='enka-home-rule__container'>
              <p>Sous chaque annonce, il y a un bouton "Contacter le vendeur". Utilisez ce bouton pour envoyer un message directement au vendeur via notre système ou sur les réseaux sociaux du vendeur </p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Comment savoir si une annonce est fiable ?</h2>
            <div className='enka-home-rule__container'>
              <p>Nous conseillons toujours de rencontrer le vendeur en personne et de ne jamais effectuer de paiement avant d'avoir vu le produit. </p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Comment gérer mes annonces ?</h2>
            <div className='enka-home-rule__container'>
              <p>Dans votre espace personnel, vous avez accès à "Mes annonces" où vous pouvez modifier, supprimer ou renouveler vos annonces actives.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Que faire si je suspecte une fraude ?</h2>
            <div className='enka-home-rule__container'>
              <p>Si une annonce ou un message vous semble suspect, contactez-nous directement pour que nous puissions enquêter.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Puis-je laisser un avis sur un vendeur ?</h2>
            <div className='enka-home-rule__container'>
              <p>Oui, après une transaction, vous pouvez laisser un avis sur le profil du vendeur pour partager votre expérience avec d'autres utilisateurs.</p>
            </div>
          </div>
          <div className='enka-home__rule enka-home-rule'>
            <h2>Quelles sont les règles de publication ?</h2>
            <div className='enka-home-rule__container'>
              <p>Les annonces doivent être claires, précises et appartenir à la catégorie adéquate. Les produits et services interdits par la loi ne sont pas autorisés sur notre site.</p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
