import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../../layout'
import { UserAuthContext } from '../../../../../context/AuthContext';
import { links } from '../../../../../routes';
import { useNavigate } from 'react-router-dom';

export function SettingCustomPage () {
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting_custom}`)
    }
  }, [token, navigate]);
  console.log(info)
  return (
    <MainLayout title='Customisation'>
        <div></div>
    </MainLayout>
  )
}
