import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "../feature/auth/auth.slice"
import eventReducer from "../feature/event/event.slice"
import postReducer from "../feature/post/post.slice"
import annonceReducer from "../feature/annonce/annonce.slice"
import pubReducer from "../feature/pub/pub.slice"
import categoryReducer from "../feature/category/category.slce"
import feedbackReducer from "../feature/feedback/feedback.slice"
import userReducer from "../feature/user/user.slice"
import partnerReducer from "../feature/partner/partner.slice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        pub: pubReducer,
        category: categoryReducer,
        annonce: annonceReducer,
        event: eventReducer,
        post: postReducer,
        feedback: feedbackReducer,
        user: userReducer,
        partner: partnerReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;