import {FC, useEffect, useRef} from "react";
import {ModalLayoutParams} from "../interface/ModalInterface";

export const ModalLayout: FC<ModalLayoutParams> = ({id, fnc, size, isOpen, children, withClose}) => {
  const modalRef = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        fnc(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fnc, isOpen])

  return (
      <div className={`kubini-modal ${isOpen ? "is--open" : ""}`} id={id}>
        <div className={`kubini-modal-container ${size}`} ref={modalRef}>
          {
            withClose ? (
              <button type={"button"} className={"kubini-modal__close-btn"} onClick={() => fnc(false)}>
                <i className="icon-delete-square"/>
              </button>
            ) : null
          }
          <div className={"kubini-modal-contain"}>
            {children}
          </div>
        </div>
      </div>
  );
};
