import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from "./auth.service";

import {APIURL, getProtecteItems, setUnProtecteItems, updateProtecteItems, updateUnProtecteItems} from "../../utils";
import { GeneralStateInterface } from '../../interface/GeneralStateInterface';

const token = window.localStorage.getItem('enka-auth')

interface AuthState extends GeneralStateInterface{
    token?: string,
    me?:  any | null,
    cities?:  any[],

    isLoginError: boolean,
    isLoginLoading: boolean,
    isLoginSuccess: boolean,

    isAvatarError: boolean,
    isAvatarLoading: boolean,
    isAvatarSuccess: boolean,

    isPasswordError: boolean,
    isPasswordLoading: boolean,
    isPasswordSuccess: boolean,

    message: any
}

const initialState: AuthState = {
    token: token ? token : "",
    me: null,
    cities: [],

    isError: false,
    isLoading: false,
    isSuccess: false,

    isLoginError: false,
    isLoginLoading: false,
    isLoginSuccess: false,

    isCreateError: false,
    isCreateLoading: false,
    isCreateSuccess: false,

    isAvatarError: false,
    isAvatarLoading: false,
    isAvatarSuccess: false,

    isPasswordError: false,
    isPasswordLoading: false,
    isPasswordSuccess: false,

    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,

    isDeleteError: false,
    isDeleteLoading: false,
    isDeleteSuccess: false,

    message: null,
}


export const login = createAsyncThunk<any, Object>(
    'auth/login',
    async (data: any, thunkAPI) => {
        try {
            return await authService.login(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const loginWithGoogle = createAsyncThunk<any, Object>(
    'auth/login-with-google',
    async (data: any, thunkAPI) => {
        try {
            return await authService.loginWithGoogle(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCitiesOption = createAsyncThunk<any, Object>(
    'auth/cities',
    async (data: any, thunkAPI) => {
        try {
            return await authService.getCitiesOption()
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getMe = createAsyncThunk<any, Object>(
    'auth/me',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/me`,
                token: tokens,
                content
            }

            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updateAvatar = createAsyncThunk<any, Object>(
    'auth/update-avatar',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-avatar`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const register = createAsyncThunk<any, Object>(
    'auth/register',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/register`,
                token: tokens,
                content
            }
            return await setUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateMyInformation = createAsyncThunk<any, Object>(
    'auth/update-information',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-information`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const activeMyAccount = createAsyncThunk<any, Object>(
    'auth/active-account',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/active-account`,
                token: tokens,
                content
            }
            
            const response = await setUnProtecteItems(data)

            if(response){
                let token = response?.access_token
                window.localStorage.setItem('enka-auth', token)

                return response
            }

        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const sendRecoverMail = createAsyncThunk<any, Object>(
    'auth/send-recover-mail',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/recover-password-mail`,
                token: tokens,
                content
            }
            return await setUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const recoverPassword = createAsyncThunk<any, Object>(
    'auth/recover-password',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/recover-password`,
                token: tokens,
                content
            }
            return await updateUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updatePassword = createAsyncThunk<any, Object>(
    'auth/update-password',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-password`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const disableAccount = createAsyncThunk<any, Object>(
    'auth/disable-account',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/disable-account`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const reactiveAccount = createAsyncThunk<any, Object>(
    'auth/reactive-account',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/reactive-account`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const deleteMyAccount = createAsyncThunk<any, Object>(
    'auth/delete-my-account',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/delete-my-account`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const logout = createAsyncThunk<any, Object>(
    'auth/logout',
    async () => {
        authService.logout()
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoginError = false
            state.isLoginLoading = false
            state.isLoginSuccess = false

            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isAvatarError = false
            state.isAvatarError = false
            state.isAvatarSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.isPasswordError = false
            state.isPasswordError = false
            state.isPasswordSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoginLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoginLoading = false
                state.isLoginSuccess = true
                // state.me = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoginLoading = false
                state.isLoginError = true
                state.message = action.payload
                // state.me = null
            })

            .addCase(loginWithGoogle.pending, (state) => {
                state.isLoginLoading = true
            })
            .addCase(loginWithGoogle.fulfilled, (state, action) => {
                state.isLoginLoading = false
                state.isLoginSuccess = true
                // state.me = action.payload
            })
            .addCase(loginWithGoogle.rejected, (state, action) => {
                state.isLoginLoading = false
                state.isLoginError = true
                state.message = action.payload
                // state.me = null
            })

            .addCase(getCitiesOption.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCitiesOption.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.cities = action.payload
            })
            .addCase(getCitiesOption.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                // state.me = null
            })
            

            .addCase(register.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                // state.me = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
                // state.me = null
            })
            

            .addCase(activeMyAccount.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(activeMyAccount.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                // state.me = action.payload
            })
            .addCase(activeMyAccount.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(reactiveAccount.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(reactiveAccount.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.me = action.payload
            })
            .addCase(reactiveAccount.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(disableAccount.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(disableAccount.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.me = action.payload
            })
            .addCase(disableAccount.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                state.me = null
            })


            .addCase(getMe.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.me = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.me = null
            })


            .addCase(sendRecoverMail.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(sendRecoverMail.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.me = action.payload
            })
            .addCase(sendRecoverMail.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(recoverPassword.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(recoverPassword.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.me = action.payload
            })
            .addCase(recoverPassword.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(updateAvatar.pending, (state) => {
                state.isAvatarLoading = true
            })
            .addCase(updateAvatar.fulfilled, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarSuccess = true
                state.me = action.payload
            })
            .addCase(updateAvatar.rejected, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(updatePassword.pending, (state) => {
                state.isPasswordLoading = true
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordSuccess = true
                // state.me = action.payload
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(deleteMyAccount.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteMyAccount.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                // state.me = action.payload
            })
            .addCase(deleteMyAccount.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
                // state.me = null
            })

            .addCase(updateMyInformation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateMyInformation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.me = action.payload
            })
            .addCase(updateMyInformation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                // state.me = null
            })

            .addCase(logout.fulfilled, (state) => {
                state.token = ""
            })
    },
})

export const { reset } = authSlice.actions
export default authSlice.reducer