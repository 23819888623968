import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../../routes';
import { deleteFeedback, getFeedbacks, reset, setFeedback, updateFeedback } from '../../../../../../redux/feature/feedback/feedback.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { AlertModule, Button, ModalLayout, Textfield } from '../../../../../components';
import { v4 as uuidV4 } from "uuid";
import { formatMyDate, formatNumber, truncate } from '../../../../../../utils/function';
import { SettingModule } from '../moule/SettingModule';


const AddFeedBack = ({closeFnc}:{closeFnc: (val: boolean) => void}) => {
  const [data, setData] = useState<any>({
    name: "", 
    content: ""
  })
  const dispatch = useAppDispatch();
  const feedbackS: any = useAppSelector((state) => state.feedback);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.name){
          setError({
              field: "name",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.content){
          setError({
              field: "content",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      setError(null)
      setCloseM(true)
      dispatch(setFeedback({...data, content: {texte: data.content}}))
  }

  const closeModal = () => {
    setData(null)
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!feedbackS.isCreateLoading} fnc={() => closeModal()}>
      {
        feedbackS.isCreateSuccess && closeM ? (
          <div className={"kubini-modal-form-status"}>
              <div className={"kubini-modal-form-status__container"}>
                  <div className={"kubini-modal-form-status__icon is--success"}>
                      <i className="icon-party-popper"/>
                  </div>
                  <div className={"kubini-modal-form-status__text"}>
                      <h2>Retour client</h2>
                      <p>Votre message a été à l'administration</p>
                      
                        <div className={"kubini-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={"Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal();
                                    // window?.location.reload()
                                }}
                            />
                        </div>
                        
                        
                  </div>
              </div>
          </div>
        ) : (
          <div className={"kubini-modal-form"}>
            <div className={"kubini-modal-form__header"}>
              <h2>Feedback</h2>
            </div>
            <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
              {
                closeM && feedbackS.isCreateError ? (
                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={feedbackS.message}/>
                ) : null
              }
              <div className={"kubini-modal-form__fields"}>
                <div className={"kubini-modal-form__field"}>
                    <Textfield
                        id={"name"}
                        value={data.name}
                        require={true}
                        changeValue={(value: any) => setData({...data, name: value})}
                        type={"text"}
                        label={"Titre"}
                        placeholder={"Ecrie ici..."}
                        error={error}
                    />
                </div>
                <div className={"kubini-modal-form__field"}>
                    <Textfield
                        id={"content"}
                        value={data.content}
                        require={true}
                        changeValue={(value: any) => setData({...data, content: value})}
                        type={"textarea"}
                        label={"Message"}
                        placeholder={"Ecrire ici..."}
                        error={error}
                    />
                </div>
              </div>

              <div className={"kubini-modal-form__action"}>
                <Button btnType={"submit"} label={feedbackS.isCreateLoading ? "Envoi en cours..." : "Envoyer"} btnStyle={"is--primary"} active={!feedbackS.isCreateLoading} withIconLeft={true} iconLeftClass={feedbackS.isCreateLoading ? "icon-spin" : "icon-send-email"} />
              </div>
            </form>
          </div>
        )
      }
    </ModalLayout>
  )
}

const ShowFeedBack = ({info, item, closeFnc}:{info: any, item: any, closeFnc: (val: boolean) => void}) => {
  // const [statut, setStatut] = useState<boolean>(false)
  
  const dispatch = useAppDispatch();
  const feedbackS: any = useAppSelector((state) => state.feedback);
  const [reply, setReply] = useState<string>("")
  const closeModal = () => {
    setReply("")
    closeFnc(false)
  }
  const replyFeedback = (reply?: string) => {
    if(reply){
      dispatch(updateFeedback({
        id: item?._id,
        replies: [ {
          id: uuidV4(),
          user: info,
          message: reply,
          date_time: new Date(),
          supprime: {
            statut: false,
            date_time: new Date(),
          }
        }, ...item?.replies]
      }))
    }
  }

  const deleteReply = (replyI: any) => {
    if(replyI){
      dispatch(updateFeedback({
        id: item?._id,
        replies: [...item?.replies?.filter((el: any) => el?.id !== replyI?.id), {
          ...replyI,
          supprime: {
            statut: true,
            date_time: new Date(),
          }
        }]
      }))
    }
  }

  return (
    <ModalLayout size={"is--xl"} isOpen={true} id={"add-modal"} withClose={!feedbackS.isEditLoading} fnc={() => closeModal()}>
      <div className='kubini-modal-feedback'>
        <div className='kubini-modal-feedback__header'>
          <span>En-tête du feedback</span>
          <h2>{item?.name}</h2>
          <p>{item?.content?.texte}</p>
        </div>
        <div className='kubini-modal-feedback__container'>
          <div className='kubini-modal-feedback__messages'>
            {
              item?.replies && item?.replies?.length > 0 ? (
                [...item.replies]
                // @ts-ignore
                .sort((a: any, b:any) => new Date(b.date_time) - new Date(a.date_time))
                .map((itemo: any, index: number) => (
                  <div className={`kubini-modal-feedback__message kubini-modal-feedback-message ${itemo?.user?._id === info?._id ? "is--me" : ""}`} key={index}>
                    <div className='kubini-modal-feedback-message__name'>
                      <strong>{itemo?.user?.fullname + (itemo?.user?._id === info?._id ? " (moi)" : " (admin)")}</strong>
                    </div>
                    <div className='kubini-modal-feedback-message__content'>
                      <p>{itemo?.message}</p>
                    </div>
                    {
                      itemo?.user?._id === info?._id ? (
                        <div className='kubini-modal-feedback-message__action'>
                          <button type='button' onClick={() => deleteReply(itemo)}>Supprimer</button>
                        </div>
                      ) : null
                    }
                    <span>{itemo?.date_time ? formatMyDate(itemo?.date_time) : ""}</span>
                  </div>
                ))
              ) : (
                <div className='kubini-modal-feedback__empty'>
                  <p>{"Vide..."}</p>
                </div>
              )
            }
          </div>
          <div className='kubini-modal-feedback__form'>
            <textarea value={reply} onChange={(e) => setReply(e.target.value)} placeholder='Ecrire...'/>
            <button type='button' onClick={() => replyFeedback(reply)}>
              <i className="icon-send-email"></i>
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  )
}

const DeleteFeedBack = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    setStatut(false)
    closeFnc(false)
  }
  const dispatch = useAppDispatch();
  const feedbackS: any = useAppSelector((state) => state.feedback);

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!feedbackS?.isDeleteLoading} fnc={() => closeModal()}>
      <div className={"kubini-modal-form"}>
        {
          statut && feedbackS?.isDeleteSuccess ? (
            <div className={"kubini-modal-form-status"}>
                <div className={"kubini-modal-form-status__container"}>
                    <div className={"kubini-modal-form-status__icon is--success"}>
                        <i className=" icon-party-popper"/>
                    </div>
                    <div className={"kubini-modal-form-status__text"}>
                        <h2>Suppression d'un feedback</h2>
                        <p>Votre feedback a bien été supprimé avec succès</p>
                        <div className={"kubini-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kubini-modal-form-status"}>
                <div className={"kubini-modal-form-status__container"}>
                    <div className={"kubini-modal-form-status__icon is--danger"}>
                        <i className="icon-delete-bin"/>
                    </div>
                    <div className={"kubini-modal-form-status__text"}>
                        <h2>Suppression d'un feedback</h2>
                        {
                            statut && feedbackS?.isDeleteError ? (
                                <p>
                                    <strong>Ouppss!!!</strong> <br/>
                                    <span>{feedbackS?.message}</span>
                                </p>
                            ) : (
                                <p>Souhaitez-vous vraiment supprimer cet élément</p>
                            )
                        }
                        <div className={"kubini-modal-form-status__action"}>
                            {
                                statut && feedbackS?.isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={feedbackS?.isDeleteLoading ? "Suppression en cours..." : "Réessayer..."}
                                        btnStyle={"is--danger"}
                                        active={!feedbackS?.isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={feedbackS?.isDeleteLoading ? "icon-spin" : "icon-bin-delete"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteFeedback({id: item?._id}))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={feedbackS?.isDeleteLoading ? "Suppression en cours..." : "Supprimer"}
                                        btnStyle={"is--danger"}
                                        active={!feedbackS?.isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={feedbackS?.isDeleteLoading ? "icon-spin" : "icon-bin-delete"}
                                        onClick={() => {
                                          setStatut(true)
                                          dispatch(deleteFeedback({id: item?._id}))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

export const FeedbacksPage = () => {
  const { info, token } = useContext(UserAuthContext);
  const [addModal, setAddModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [itemSelect, setItemSelect] = useState<any>(null)
  const [limit, setLimit] = useState<number>(12)
  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, feedbacks, feedback, message}: any = useAppSelector((state) => state.feedback);

  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting_feedback}`)
    }
  }, [token, navigate]);

  useEffect(() => {
    dispatch(getFeedbacks({}))
    return () => {
        dispatch(reset())
    }
  }, [dispatch])

  useEffect(() => {
    if(feedback){
      setItemSelect(feedback)
    }
  }, [feedback])

  return (
    <MainLayout title='Feedbacks'>
       <div className='enka-home'>
        <div className='enka-home__account enka-home-account'>
          <SettingModule/>
          <div className='enka-home-account__container enka-home-account-container'>
            {
              isLoading ? (
                <div className={"kubini-loader"}>
                  <i className="icon-spin"/>
                </div>
              ) : (
                isError ? (
                  <div className={"kubini-page-error"}>
                    <div className={"kubini-page-error__container"}>
                      <i className="icon-warning-triangle"/>
                      <h2>{message}</h2>
                    </div>
                  </div>
                ) : (
                  isSuccess && feedbacks && info ? (
                    <div className='enka-home-account__list enka-home-account-list'>
                      <div className='enka-home-account-list__header'>
                        <h2>Feedback</h2>
                        <div className='enka-home-account-list__action'>
                          <Button btnType='button' btnStyle='is--primary' label='Envoyer un feedback' active={true} onClick={() => {
                            setAddModal(true)
                          }}/>
                        </div>
                      </div>
                      <div className='enka-home-account-list__container'>
                        {
                          feedbacks?.filter((el: any) => el?.auth?._id === info?._id)?.length > 0 ? (
                            feedbacks?.filter((el: any) => el?.auth?._id === info?._id)
                            .slice(0, limit)
                            .map((item: any, index: number) => (
                              <div className='enka-home-account-grid-item type2' key={index}>
                                <div className='enka-post'>
                                  <div className='enka-post-info'>
                                    <div className='enka-card-info-id'>
                                      <h2>{item?.name ? truncate(item?.name, 80) : "ND"}</h2>
                                      <p>{item?.content?.texte  ? truncate(item?.content?.texte, 80) : "ND"} </p>
                                    </div>
                                    <div className='enka-post-info-stats'>
                                      <div className='enka-post-info-stat'>
                                        <div className='enka-post-info-stat__item'>
                                          <i className="icon-mail-send-forward-email"></i> <span>{item?.replies?.length > 0 ? formatNumber(item?.replies?.length) : "Aucune"} reponse{item?.replies?.length > 1 ? "s" : ""}</span>
                                        </div>
                                      </div>
                                      <div className='enka-post-info-stats__btn'>
                                        <Button btnType='button' btnStyle='is--neutral' label='Voir' active={true} onClick={() => {
                                          setItemSelect(null)
                                          setItemSelect(item)
                                          setShowModal(true)
                                        }}/>
                                        <Button btnType='button' btnStyle='is--danger' label='Supprimer' active={true} onClick={() => {
                                          setItemSelect(null)
                                          setItemSelect(item)
                                          setDeleteModal(true)
                                        }}/>
                                      </div>
                                      <span className='enka-post-info-stats__action'>Envoyé {formatMyDate(item?.createdAt)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <strong>Aucun element enregistré...</strong>
                          )
                        }
                      </div>

                      {
                        feedbacks?.filter((el: any) => el?.auth?._id === info?._id)?.length > 12 ? (
                          <div className='enka-home-account-action'>
                            <Button btnType={"button"} label={"Afficher plus"} btnStyle={"is--neutral"} active={true} onClick={() => setLimit(limit + 12)}/>
                          </div>
                        ) : null
                      }
                      {
                        addModal ? <AddFeedBack closeFnc={setAddModal} /> : null
                      }
                      {
                        itemSelect && deleteModal ? <DeleteFeedBack item={itemSelect} closeFnc={setDeleteModal} /> : null
                      }
                      {
                        itemSelect && showModal ? <ShowFeedBack item={itemSelect} closeFnc={setShowModal} info={info} /> : null
                      }
                    </div>
                  ) : null
                )
              )
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
