import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';

export function MessageriePage() {
  // const {slug} = useParams()
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.messagerie }`)
    }
  }, [token, navigate]);
  console.log(info)
  return (
    <MainLayout title='Messagerie'>
        <div></div>
    </MainLayout>
  )
}
