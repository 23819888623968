import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface UserState extends GeneralStateInterface{

    user:  any,
}

const initialState: UserState = {

    user: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    message: null,
}


export const getUser = createAsyncThunk<any, Object>(
    'user/show',
    async (username: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/show/${username}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const signalerUser = createAsyncThunk<any, Object>(
    'user/signaler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/signaler`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false
            
            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            
            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(signalerUser.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(signalerUser.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.user = action.payload
            })
            .addCase(signalerUser.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })
    }
})

export const { reset } = userSlice.actions
export default userSlice.reducer