import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AlertModule, Button, Textfield } from '../../../../../components';
import { updatePassword } from '../../../../../../redux/feature/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import { SettingModule } from '../moule/SettingModule';


const EditMyPassword = () => {
  const dispatch = useAppDispatch();
  const { isPasswordLoading, isPasswordError, isPasswordSuccess, message } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<any>({
    password: "", 
    newPassword: "", 
    confirmPassword: ""
  })
  
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.password){
        setError({
            field: "password",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.newPassword){
        setError({
            field: "newPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.confirmPassword){
        setError({
            field: "confirmPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    dispatch(updatePassword(data))
} 

  return (
   
    <div className='enka-home-account__form enka-home-account-form'>
      <h2>Mot de passe</h2>
      <form className='enka-home-account-form__container' onSubmit={handleSumbit}>
        {
          isPasswordSuccess ? (
            <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
          ) : (
              isPasswordError ? (
                  <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
          )
        }
        <div className='enka-home-account-form__fields'>
          <div className='enka-home-account-form__field'>
            <Textfield
              id={"password"}
              value={data.password}
              require={true}
              changeValue={(value: any) => setData({...data, password: value})}
              type={"password"}
              label={"Mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
          <div className='enka-home-account-form__field'>
            <Textfield
              id={"newPassword"}
              value={data.newPassword}
              require={true}
              changeValue={(value: any) => setData({...data, newPassword: value})}
              type={"password"}
              label={"Nouveau mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
          <div className='enka-home-account-form__field'>
            <Textfield
              id={"confirmPassword"}
              value={data.confirmPassword}
              require={true}
              changeValue={(value: any) => setData({...data, confirmPassword: value})}
              type={"password"}
              label={"Confirmation du nouveau mot de passe"}
              placeholder={"Eg: *****"}
              error={error}
            />
          </div>
        </div>
        <div className='enka-home-account-form__action'>
          <Button btnType={"submit"}label={isPasswordLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={true} iconLeftClass={isPasswordLoading ? "kbn-icon-spin6" : "kbn-icon-save"}/>
        </div>
      </form>
    </div>
  )
}

export const SettingPasswordPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting_password}`)
    }
  }, [token, navigate]);

  return (
    <MainLayout title='Mot de passe'>
        <div className='enka-home'>
          <div className='enka-home__account enka-home-account'>
            <SettingModule/>
            <div className='enka-home-account__container enka-home-account-container'>
              <EditMyPassword/>
            </div>
          </div>
        </div>
    </MainLayout>
  )
}
