import React, {createContext, useEffect} from "react";
import useLocalStorage from "use-local-storage";

export const ThemeContext = createContext<null | any>(null)

export const fonts = [
  {
    value: "default",
    label: "Par défaut: Lufga Font",
  },
  {
    value: "space_grotesk",
    label: "Space Grotesk",
  },
  {
    value: "hanken_grotesk",
    label: "Hanken Grotesk",
  },
  {
    value: "montserrat",
    label: "Montserrat",
  },
  {
    value: "playfair",
    label: "Playfair",
  },
  {
    value: "roboto",
    label: "Roboto",
  },
]



interface ThemeContextParams {
    children?: React.ReactNode
}

export const ThemeProvider: React.FC<ThemeContextParams> = ({children}) => {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const [fontSize, setFontsize] = useLocalStorage('fontsize', "14");
    const [colorPrimary, setColorPrimary] = useLocalStorage('colorPrimary', "default");
    const [textPosition, setTextPosition] = useLocalStorage('textPosition', "default");
    const [fontFamily, setFontFamily] = useLocalStorage('fontFamily', "default");
    const [bordure, setBordure] = useLocalStorage('bordure', "default");
    const [defaultTheme, setDefaultTheme] = useLocalStorage('isDefaultTheme', "ok");

    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setDefaultTheme("nope")
        setTheme(newTheme)
    }
    const swichtDefautTheme = () => {
        setDefaultTheme("ok")
    }
    const switchPrimaryColor = (value : "default" | "blue" | "gold" | "black" | "green" | "pink" | "orange" | "violet") => {
        setColorPrimary(value)
    }
    const switchTextPosition = (value : "default" | "right" | "center") => {
        setTextPosition(value)
    }
    const switchBordure = (value : "default" | "square" ) => {
        setBordure(value)
    }
    const changeFontFamily = (value : "default" | "space_grotesk" | "hanken_grotesk" | "montserrat" | "playfair" | "roboto") => {
        setFontFamily(value)
    }

    const changeFontSize = (value: any) => {
        setFontsize(value)
    }

    useEffect(() => {
        let body: any = document.querySelector("body")
        if(theme === "light"){
            body && (body.style.background = "#fff")
        }else{
            body && (body.style.background = "#121212")
        }
    }, [theme]);


    useEffect(() => {
        const rootStyles = getComputedStyle(document.documentElement);
        if(rootStyles){
            document.documentElement.style.fontSize = parseInt(fontSize) + "px"
        }
    }, [fontSize])


    return (
        <ThemeContext.Provider value={{
            theme,
            fontSize,
            colorPrimary,
            textPosition,
            fontFamily,
            bordure,
            defaultDark,
            defaultTheme,
            switchTheme,
            changeFontSize,
            swichtDefautTheme,
            switchPrimaryColor,
            switchTextPosition,
            changeFontFamily,
            switchBordure,
        }}>
            {children}
        </ThemeContext.Provider>
    );
};
