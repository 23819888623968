import React from 'react'
import { useAppSelector } from '../../../../../redux/store';
import { addDays, format, parseISO } from 'date-fns';
import { SliderNextArrow, SliderPrevArrow } from '../../home';
import { useNavigate } from 'react-router-dom';
import { capitalize, formatMyDate, formatNumber, truncate } from '../../../../../utils/function';
import { fr } from 'date-fns/locale';
import { links } from '../../../../routes';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";

export const EventContainer = ({search}:{search: string}) => {
  const {isLoading, isError, isSuccess, events, message} = useAppSelector((state) => state.event);


  let setting = {
    dot: false,
    infinite: events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? true : false,
    autoplaySpeed: 2000,
    prevArrow: events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date()).length > 4 ?  <SliderNextArrow/> : <></>,
 
  }
  let navigate = useNavigate()
  return (
    <>
    {
      isLoading ? (
        <><i className="icon-spin"></i> <span>Chargement...</span></>
      ) : (
        isError ? (
          <><i className="icon-warning-triangle"></i> <strong>{message}</strong> <button type='button' style={{textDecoration: "underline", opacity: .8}} onClick={() => window?.location?.reload()}>Reactualiser...</button></>
        ) : (
          isSuccess && events ? (
            events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter(ev => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.length > 0 ? (
              <div className='enka-home-slide-section'>
                <div className='enka-home-slide-section__header enka-home-slide-section-header'>
                  <h2>Evènements</h2>
                  
                </div>
                <div className='enka-home-slide-section__container'>
                  <Slider {...setting}>
                    {
                      events?.filter((el: any) => el.title?.toLowerCase().includes(search.toLocaleLowerCase())).filter((ev : any) => addDays(parseISO(`${ev.date_event}`), 1) > new Date())?.slice(0, 12).map((item: any, index: number) => (
                        <div className='enka-home-slide-item' key={index}>
                          <button className='enka-card' type='button' onClick={() => navigate(links.event + item?.slug)}>
                            {
                              item.epingle ? (
                                <div className='enka-card__important'>
                                  <i className="icon-pin-2"></i>
                                </div>
                              ) : null
                            }
                              <div className='enka-card-image'>
                                {
                                  item?.images?.length > 0 ? (
                                    item?.images[0]?.url ? (
                                      <img src={item?.images[0]?.url} alt="cover" />
                                    ) : (
                                      <i className="icon-picture-flower"></i>
                                    )
                                  ) : (
                                    <i className="icon-picture-flower"></i>
                                  )
                                }
                              </div>
                              <div className='enka-card-info'>
                                <div className='enka-card-info-id'>
                                  <strong>{item?.title ? truncate(item?.title, 80) : "ND"}</strong>
                                  <h2>{item?.date_event ? capitalize(format(new Date(item?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</h2>
                                  
                                  <span>{"Lieu :" + item?.lieu?.toUpperCase()}</span> 
                                </div>

                                <div className='enka-card-info-stats'>
                                  <div className='enka-card-info-user'>
                                    <div className='enka-card-info-user__avatar'>
                                    {
                                      item?.user?.auth?.avatar ? (
                                        <img src={item?.user?.auth?.avatar?.url} alt="" />
                                      ) : (
                                        <i className="icon-user-single"></i>
                                      )
                                    }
                                    </div>
                                    <div className='enka-card-info-user__info'>
                                      <strong>{item?.user?.auth?.fullname}</strong>
                                      <span>{item?.user?.city?.name || ""}</span>
                                    </div>
                                  </div>
                                  <div className='enka-card-info-stat'>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-favorite-heart"></i> <span>{item?.likes?.length > 0 ? formatNumber(item?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                    </div>
                                    <div className='enka-card-info-stat__item'>
                                      <i className="icon-chat-two-bubbles-oval"></i> <span>{item?.comments?.length > 0 ? formatNumber(item?.comments?.length) : "Aucun"} commentaire(s)</span>
                                    </div>
                                  </div>
                                  <span className='enka-card-info-stats__action'>Publiée {formatMyDate(item?.createdAt)}</span>
                                </div>
                              </div>
                          </button>
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            ) : (
                <strong>Aucun evenement trouvé...</strong>
            )
          ) : null
        )
      )
    }
    </>
  )
}
