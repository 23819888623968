import axios from "axios"


export const getProtecteItems =  async (data: any ) => {
    let {url, token} = data

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get( url, config)
    
    return response.data
}
export const getUnProtecteItems =  async (data: any) => {
    let {url} = data

    const response = await axios.get( url)
    return response.data
}
export const setProtecteItems =  async (data: any) => {
    let {url, token, content} = data

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(  url, content, config)
    return response.data
}
export const setUnProtecteItems =  async (data: any) => {
    let {url, content} = data

    const response = await axios.post( url, content)
    return response.data
}

export const updateUnProtecteItems =  async (data: any) => {
    let {url, content} = data

    const response = await axios.patch( url, content)
    return response.data
}
export const updateProtecteItems =  async (data: any) => {
    let {url, token, content} = data

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.patch( url, content, config)

    return response.data
}
export const deleteProtecteItems =  async (data: any) => {
    let {url, token} = data

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete( url, config)
    return response.data
}
export const deleteUnProtecteItems =  async (data: any) => {
    let {url} = data

    const response = await axios.delete( url)
    return response.data
}