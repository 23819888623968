import React, { ChangeEvent, Fragment, KeyboardEvent, useEffect, useState } from 'react'
import { MainLayout } from '../../../layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { getArticles, reset } from '../../../../redux/feature/annonce/annonce.slice';
import { getEvents, reset as ereset } from '../../../../redux/feature/event/event.slice';
import { getPosts, reset as preset } from '../../../../redux/feature/post/post.slice';
import { getPubs, reset as areset } from '../../../../redux/feature/pub/pub.slice';
import { links } from '../../../routes';
import Slider from 'react-slider';
import { ArticleContainer, EventContainer, PostContainer } from './module';

const MIN = 0
const MAX = 10000000

const CategorieSide = ({categories, subcategories}:{categories: string[], subcategories: string[]}) => {
  const categoryState: any = useAppSelector((state) => state.category);
  let navigate = useNavigate()


  return (
    <div className='enka-search-side__categories enka-search-side-categories'>
      <h3>Catégories</h3>
      <div className='enka-search-side-categories__list'>
        {
          categoryState.categories?.length > 0 ? (
            categoryState.categories.map((item: any, index: number) => (
              <Fragment key={index}>
                <div className='enka-search-side-categories__header'>
                  <div className='enka-search-side-categorie'>
                  <span>{item?.name}</span>
                  </div>
                </div>
                <div className='enka-search-side-categories__subcategories'>
                  {
                    item?.subcategories?.length > 0 ? (
                      item?.subcategories.map((itm: any, idx: number) => (
                        <button key={idx} className='enka-search-side-categorie' type='button' onClick={() => {
                          if(subcategories.filter((el: any) => el === item.slug).length > 0){
                            navigate(links.search+`?wordkeys=${subcategories.filter((el: string) => el !== itm.slug).join(",")}&type=subcategory`)
                          }else{
                            navigate(links.search+`?wordkeys=${[...subcategories, itm?.slug].join(",")}&type=subcategory`)
                          }
                        }}>
                          <div className={`enka-search-side-categorie__box ${(categories.filter((el: any) => el.includes(item.slug)).length > 0 || subcategories.filter((el: string) => el.toLowerCase().includes(itm.slug.toLowerCase())).length > 0) ? "is--active" : ""}`}></div> <span>{itm?.name}</span>
                        </button>
                      ))
                    ) : null
                  }
                </div>
              </Fragment>
            ))
          ) : <strong>Aucune categorie</strong>
        }
      </div>
    </div>
  )
}

export const SearchPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyWords = searchParams.get('wordkeys')
  const typeSearch = searchParams.get('type')
  const [search, setSearch] = useState<string>("")
  const [categorieS, setCategoryS] = useState<any[]>([])
  const [subcategorieS, setSubCategoryS] = useState<any[]>([])
  const [values, setValues] = useState<any>([MIN, MAX])
  const [minValue, setMinValue] = useState<number>(MIN)
  const [maxValue, setMaxValue] = useState<number>(MAX)

  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, pubs, message} = useAppSelector((state) => state.pub);
  let navigate = useNavigate()

  useEffect(() => {
    setValues([minValue, maxValue])
  }, [minValue, maxValue])

  useEffect(() => {
    if(values){
      setMinValue(values[0])
      setMaxValue(values[1])
    }
  }, [values])

  useEffect(() => {
    if(typeSearch === "all"){
      setSearch(keyWords || "")
      if(keyWords){
        setCategoryS(keyWords.split(","))
        setSubCategoryS(keyWords.split(","))
      }
    }
    if(typeSearch === "category"){
      if(keyWords){
        setCategoryS(keyWords.split(","))
      }
    }
    if(typeSearch === "subcategory"){
      if(keyWords){
        setSubCategoryS(keyWords.split(","))
      }
    }
  }, [typeSearch, keyWords])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // e.preventDefault()
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if(search) navigate(links.search + `?wordkeys=${search}&type=all`)
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(getArticles({}))
    dispatch(getEvents({}))
    dispatch(getPosts({}))
    dispatch(getPubs({}))
    return () => {
      dispatch(reset())
      dispatch(preset())
      dispatch(areset())
      dispatch(ereset())
    }
  }, [dispatch])

  if(isLoading){
    return (
      <div className={"kubini-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }
  if(isError){
    return (
      <div className={"kubini-user-error"}>
        <div className={"kubini-user-error__container"}>
          <i className="icon-warning-triangle"/>
          <h2>Oupss!! Une erreur est survenue</h2>
          <p>{message}</p>
          <div className={"kubini-user-error__action"}>
            <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <MainLayout title='Recherche'>
      <div className='enka-home'>
        {
          isSuccess && pubs ? (
            pubs?.filter((el: any) => el?.type_pub === "search-top")?.length > 0 ? (
              <div  className='enka-ads is--top' >
                <a href={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.link} target='_blank' rel='noreferrer'>
                <img src={pubs?.filter((el: any) => el?.type_pub === "home-top")[0]?.cover?.url} alt="ad" />
                <span>publicité</span>
                </a>
              </div>
            ) : null
          ) : null
        }
        <div className='enka-search-page'>
          <div className='enka-search-side'>
            <h2>Recherche</h2>
            <div className='enka-search-side__search'>
              <i className="icon-search"></i>
              <input 
                type="search" 
                placeholder='Mots clés (appuyer sur Entrée pour effectuer la recherche)' 
                value={search} 
                onChange={handleChange} 
                onKeyDown={handleKeyDown}  />
            </div>
            {/* <div className='enka-search-side__range enka-search-side-range'>
              <h3>Prix</h3>
              <div className='enka-search-side-range__container'>
                <div className='enka-search-side-range__prices enka-search-side-range-prices'>
                  <div className='enka-search-side-range__price enka-search-side-range-price'>Min: <input type="number" value={minValue} onChange={(e) => setMinValue(parseInt(e.target.value))} min={MIN} max={MAX} /></div>
                  <div className='enka-search-side-range__price enka-search-side-range-price'>Max: <input type="number" value={maxValue} onChange={(e) => setMaxValue(parseInt(e.target.value))} min={MIN} max={MAX} /></div>
                </div>
                <Slider className='enka-search-range' onChange={setValues} value={values} min={MIN} max={MAX}/>
              </div>
            </div> */}
            <CategorieSide categories={categorieS} subcategories={subcategorieS}/>
          </div>
          <div className='enka-search-container'>
            <EventContainer search={search}/>
            <PostContainer search={search}/>
            <ArticleContainer type={typeSearch || "all"} categories={categorieS} subcategories={subcategorieS} search={search} valeurs={values}/>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
