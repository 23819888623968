import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, getUnProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface ArticleState extends GeneralStateInterface{
    articles:  any[],
    article:  any,
}
const initialState: ArticleState = {
    articles: [],
    article: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const getArticles = createAsyncThunk<any, Object>(
    'article/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/list`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getMyArticles = createAsyncThunk<any, Object>(
    'article/my-list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/my-list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getArticle = createAsyncThunk<any, Object>(
    'article/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const setArticle = createAsyncThunk<any, Object>(
    'article/create',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/create`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updateArticle = createAsyncThunk<any, Object>(
    'article/update',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/update`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const deleteArticle = createAsyncThunk<any, Object>(
    'article/delete',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/delete`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const likeArticle = createAsyncThunk<any, Object>(
    'article/like',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/like`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unlikeArticle = createAsyncThunk<any, Object>(
    'article/unlike',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/unlike`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const cartArticle = createAsyncThunk<any, Object>(
    'article/cart',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/cart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const uncartArticle = createAsyncThunk<any, Object>(
    'article/uncart',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/uncart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const signalerArticle = createAsyncThunk<any, Object>(
    'article/signaler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/signaler`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const commentArticle = createAsyncThunk<any, Object>(
    'article/comment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/comment`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const uncommentArticle = createAsyncThunk<any, Object>(
    'article/uncomment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/delete-comment`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false


            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false


            state.isCreateError = false
            state.isCreateSuccess = false
            state.isCreateLoading = false

            state.isDeleteError = false
            state.isDeleteSuccess = false
            state.isDeleteLoading = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getArticles.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getArticles.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.articles = action.payload
        })
        .addCase(getArticles.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getMyArticles.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getMyArticles.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.articles = action.payload
        })
        .addCase(getMyArticles.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

            .addCase(getArticle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.article = action.payload
            })
            .addCase(getArticle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(setArticle.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setArticle.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.article = action.payload
            })
            .addCase(setArticle.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(likeArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(likeArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(likeArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unlikeArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unlikeArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(unlikeArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(cartArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(cartArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(cartArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncartArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncartArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(uncartArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(commentArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(commentArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(commentArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncommentArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncommentArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(uncommentArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })
            .addCase(signalerArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(signalerArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(signalerArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(updateArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteArticle.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteArticle.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.articles = state.articles.filter((category: any) => category?._id !== action.payload?._id)
            })
            .addCase(deleteArticle.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

    }
})

export const { reset } = articleSlice.actions
export default articleSlice.reducer