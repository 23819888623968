import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface PubState extends GeneralStateInterface{
    pubs:  any[],
    pub:  any,
}

const initialState: PubState = {

    pubs: [],
    pub: [],

    isError: false,
    isLoading: false,
    isSuccess: false,


    message: null,
}


export const getPubs = createAsyncThunk<any, Object>(
    'pub/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const pubSlice = createSlice({
    name: 'pub',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPubs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPubs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.pubs = action.payload
            })
            .addCase(getPubs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

    }
})

export const { reset } = pubSlice.actions
export default pubSlice.reducer