import React from 'react'
import { MainLayout } from '../../../layout'

export function ErrorPage() {

  return (
    <MainLayout title='PAge introuvable'>
      <div className='enka-home'>
        <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Page introuvable</h2>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
