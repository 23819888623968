import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface EventState extends GeneralStateInterface{
    events:  any[],
    event:  any,
}
const initialState: EventState = {
    events: [],
    event: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}

export const getEvents = createAsyncThunk<any, Object>(
    'event/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getEvent = createAsyncThunk<any, Object>(
    'event/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getMyEvents = createAsyncThunk<any, Object>(
    'event/my-list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/my-list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const setEvent = createAsyncThunk<any, Object>(
    'event/create',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/create`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updateEvent = createAsyncThunk<any, Object>(
    'event/update',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/update`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const deleteEvent = createAsyncThunk<any, Object>(
    'event/delete',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/delete`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const likeEvent = createAsyncThunk<any, Object>(
    'event/like',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/like`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unlikeEvent = createAsyncThunk<any, Object>(
    'event/unlike',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/unlike`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const cartEvent = createAsyncThunk<any, Object>(
    'event/cart',
    async ( content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/cart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const uncartEvent = createAsyncThunk<any, Object>(
    'event/uncart',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/uncart`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const signalerEvent = createAsyncThunk<any, Object>(
    'event/signaler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/signaler`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const commentEvent = createAsyncThunk<any, Object>(
    'event/comment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/comment`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const uncommentEvent = createAsyncThunk<any, Object>(
    'event/uncomment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/delete-comment`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false


            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false


            state.isCreateError = false
            state.isCreateSuccess = false
            state.isCreateLoading = false

            state.isDeleteError = false
            state.isDeleteSuccess = false
            state.isDeleteLoading = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEvents.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getEvents.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.events = action.payload
        })
        .addCase(getEvents.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

        .addCase(getMyEvents.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getMyEvents.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.events = action.payload
        })
        .addCase(getMyEvents.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

            .addCase(getEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.event = action.payload
            })
            .addCase(getEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(setEvent.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setEvent.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.event = action.payload
            })
            .addCase(setEvent.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(likeEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(likeEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(likeEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unlikeEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unlikeEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(unlikeEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(cartEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(cartEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(cartEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncartEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncartEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(uncartEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(commentEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(commentEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(commentEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(uncommentEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncommentEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(uncommentEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(signalerEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(signalerEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(signalerEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteEvent.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.events = state.events.filter((category: any) => category?._id !== action.payload?._id)
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

    }
})

export const { reset } = eventSlice.actions
export default eventSlice.reducer