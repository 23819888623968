import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface CategoryState extends GeneralStateInterface{
    feedbacks:  any[],
    feedback:  any,
}

const initialState: CategoryState = {
    feedbacks: [],
    feedback: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setFeedback = createAsyncThunk<any, Object>(
    'feedback/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}feedback/create`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const getFeedbacks = createAsyncThunk<any, Object>(
    'feedback/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}feedback/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const getFeedback = createAsyncThunk<any, Object>(
    'feedback/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}feedback/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const updateFeedback = createAsyncThunk<any, Object>(
    'feedback/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}feedback/update`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const deleteFeedback = createAsyncThunk<any, Object>(
    'feedback/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}feedback/delete`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const feedbackSlice: any = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeedbacks.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFeedbacks.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.feedbacks = action.payload
            })
            .addCase(getFeedbacks.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getFeedback.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFeedback.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.feedback = action.payload
            })
            .addCase(getFeedback.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setFeedback.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setFeedback.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.feedbacks = [action.payload, ...state.feedbacks]
            })
            .addCase(setFeedback.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateFeedback.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateFeedback.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.feedbacks = [action.payload, ...state.feedbacks.filter((category: any) => category?._id !== action.payload?._id)]
                state.feedback = action.payload
            })
            .addCase(updateFeedback.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteFeedback.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteFeedback.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.feedbacks = state.feedbacks.filter((category: any) => category?._id !== action.payload?._id)
            })
            .addCase(deleteFeedback.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = feedbackSlice.actions
export default feedbackSlice.reducer